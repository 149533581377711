::ng-deep .ops-paginator.mat-mdc-paginator {
  background: transparent;

  .mat-mdc-paginator-navigation-previous, .mat-mdc-paginator-navigation-next {
    color: #798AA3;
  }

  .mat-mdc-paginator-container {
    color: var(--main-text);

    .mat-mdc-text-field-wrapper {
      color: var(--main-text);

      .mat-mdc-form-field-flex {
        .mdc-notched-outline__leading {
          border: none;
        }
        .mdc-notched-outline__trailing {
          border-radius: 0;
          border: none;
          border-bottom: 1px solid #FBC02D;
        }
      }
    }
  }
}

.mat-mdc-paginator-icon {
  fill: #798AA3 !important;
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  opacity: 0.5 !important;
}

::ng-deep .mat-mdc-paginator-container {
  background: var(--app-bg);
}
