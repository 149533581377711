::ng-deep .p-splitter {
  background: transparent;
  border: none;
  overflow: auto;
  color: var(--main-text);
  padding: 0 5px;

  .p-splitter-panel-nested:first-child {
    flex-direction: column;
  }
}

.splitter-chat {
  height: 100%;

  ::ng-deep .p-splitter {
    height: 100%;
    background: transparent;
    border: none;
  }
}

::ng-deep .split-area-gpt {
  .as-split-gutter {
    //z-index: 1001;
  }
}

::ng-deep .as-split-gutter {
  background-color: transparent !important;
}
