.opster-inline-message {
  &.p-inline-message {
    .p-inline-message-text {
      font-size: 13px;
    }
  }
}

.opster-inline-messages {
  .p-message {
    display: inline-flex;
    border-width: 0;

    .p-message-wrapper {
      font-size: 13px;
      padding: 0.5rem;
      border-radius: 3px;

      i {
        margin-right: 0.5rem;
      }
    }
  }
}

