.settings-wizard-steps {
  position: absolute;
  top: 0;
  z-index: 100;
  background: var(--panel-content-bg);
  //width: calc(100vw - 341px);
  width: 100%;
  display: grid;
  align-items: baseline;
  gap: 2rem;
  grid-template-columns: auto 1fr;
  padding: 2rem 1rem 1.5rem 1rem;


  .p-steps-opster {
    width: 60%;
    //margin-right: 3em;
    font-size: 0.8rem;
  }

  .p-steps-opster .p-steps-item {
    //width: 270px;

    &:before {
      border-width: 5px;
      //width: 160px;
      //margin-left: 160px;
      //border-radius: 10px;
    }

    //&:last-child {
    //  &:before {
    //    border: none;
    //  }
    //}

    .p-menuitem-link {
      background: transparent;

      .p-steps-title {
        color: var(--main-text);
        padding: 0 0.5em;
      }
    }
  }

  .p-steps-opster .p-steps-item.p-highlight .p-steps-number {
    background: #2395EC;
    color: white;
  }

}

.wizard-actions {
  margin: 4rem 0 2rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4rem;
  height: 100%;
  position: relative;

  .checking-connection-msg {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    font-size: 0.8rem;

    &.red {
      color: #FF6A6A;

    }

  }
}
