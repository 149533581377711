@import "../../../assets/common-sizes";

.sidebar-big {
  width: 60vw !important;
}

.sidebar-small {
  width: 40vw !important;
  min-width: 800px;
}

.sidebar-append-to {
  width: 80% !important;
}

@media all and (max-width: $large-screen-width) {
  .sidebar-big {
    width: 70vw !important;
  }

  .sidebar-small {
    width: 50vw !important;
    min-width: 800px;
  }
}

@media all and (max-width: $lg) {
  .sidebar-big {
    width: 80vw !important;
  }

  .sidebar-small {
    width: 60vw !important;
    min-width: 800px;
  }
}

.p-sidebar {
  padding: 0;
  border-radius: 20px 0 0 20px;
  z-index: 1000;
  transition: width 0.2s !important;
  display: block;

  // sidebar with left severity column
  &.sidebar-left-severity {
    min-width: 800px;
    position: absolute;
    display: block;

    &.body {
      position: fixed;
    }

    .p-sidebar-header,
    .p-sidebar-footer{
      padding: 0;
    }

    .p-sidebar-content {
      display: grid;
      grid-template-columns: 50px auto;
      height: 100%;
      background-color: var(--panel-content-sidebar);
      color: var(--main-text);
      border-radius: 20px 0 0 20px;
      padding: 0;

      .analytics {
        padding: 10px;

        &.analytics-graph {
          display: grid;
          grid-template-columns: 50% 49%;
          grid-gap: 10px;
        }

        .read-more-notifications {
          display: grid;
          grid-template-columns: 50% 50%;
          grid-gap: 10px;

          &.one {
            grid-template-columns: auto;
          }
        }
      }

      .left-severity {

        .col-icon {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .mat-icon {
            width: 33px;
            height: 33px;
          }

          &.LOW {
            background: var(--severity-low);
          }

          &.MEDIUM {
            background: var(--severity-medium);
          }

          &.HIGH {
            background: var(--severity-high);
          }

        }
      }

      .sidebar-top {
        position: sticky;
        top: 0;
        padding: 0 10px;
        z-index: 5;
        background-color: var(--panel-content-sidebar);

        &.suspects-sidebar {
          padding: 0;
        }

        .actions-wrapper {
          display: flex;
          justify-content: flex-end;

          .close {
            margin: 24px 0;
            cursor: pointer;

            &.p-sidebar-close {
              padding: 24px 0;
            }
          }
        }

        .sidebar-top-date {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 60px;
          padding: 10px 0;

          mat-icon {
            cursor: pointer;
            width: 16px;
            height: 16px;
          }
        }

        .sidebar-top-title {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          gap: 20px;
          color: var(--main-text);
          border-bottom: 1px solid var(--main-text-opacity-30);
          padding-bottom: 20px;

          &.suspects-sidebar {
            padding-bottom: 5px;
          }

          .date-wrapper {
            display: flex;
            align-items: center;

            .date {
              white-space: nowrap;
              opacity: 0.5;
              font-size: 0.8rem;
              margin-right: 20px;
            }

            .sliders-horiz-btn {
              cursor: pointer;
              display: flex;

              &.disable {
                opacity: 0.6;
                pointer-events: none;
              }
            }
          }

          .title {
            max-width: 65%;
            font-size: 20px;
            font-weight: bold;
            display: flex;
            align-items: center;

            &.suspects-sidebar {
              margin: 0 0 6px 12px;
            }

            p {
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              margin: 0;
              word-break: break-word;
            }

            .date {
              font-size: 13px;
              font-weight: 400;
              opacity: 0.5;
              white-space: nowrap;
              margin-left: 10px;
            }
          }

          .index-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .category {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            opacity: 0.5;
          }
        }
      }
    }

    @media all and (min-width: $xl-screen-width) {
      .p-sidebar-content {
        .sidebar-top {
          .sidebar-top-date {
            mat-icon {
              height: 24px;
              width: 24px;
            }

          }

          .sidebar-top-title {
            .title {
              font-size: 28px;
            }

            .index-name {
              font-size: 22px;
            }
          }
        }
      }
    }

    @media all and (max-width: $large-screen-width) {
      &.sidebar-left-severity {
        min-width: 700px;
      }
    }

    @media all and (max-width: $lg) {
      &.sidebar-left-severity {
        min-width: 550px;
      }
    }

    @media all and (max-width: $small-screen-width) {
      &.sidebar-left-severity {
        min-width: unset;
      }
    }
  }

  // sidebar without left severity column - instructions
  &.sidebar-single-column {

    .p-sidebar-header {
      background-color: var(--panel-content-sidebar);
      border-radius: 20px 0 0 0;
    }

    .p-sidebar-content {
      display: grid;
      grid-template-columns: auto;
      height: 100%;
      background-color: var(--panel-content-sidebar);
      color: var(--main-text);
      border-radius: 0 0 0 20px;
      padding: 0;
    }
  }
}

.sidebar-panel {
  background: var(--panel-content-sub);
  color: var(--main-text);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  height: auto;
  position: relative;

  .panel-title {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 600;

    &.suspects-sidebar {
      font-size: 0.9rem;
    }

    .mat-icon {
      margin-right: 10px;
      width: 20px;
      height: 20px;

      &.gpt {
        width: 24px;
        height: 24px;
      }

      svg path {
        fill: var(--icon-color) !important;
      }
    }
  }

  .sidebar-chart-wrapper {
    min-height: 400px;
    height: 100%;
    width: 100%;
  }

  .panel-content {
    font-size: 15px;
    height: 100%;
    font-weight: normal;
    padding-left: 34px;

    a:-webkit-any-link {
      color: var(--panel-content-sub-curl-link);
    }
  }

  @media all and (min-width: $xl-screen-width) {
    .panel-title {
      font-size: 18px;

      &.suspects-sidebar {
        font-size: 1.2rem;
      }

      .mat-icon {
        margin-right: 10px;
        width: 1.5rem;
        height: 1.5rem;

        &.gpt {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    }
  }
}
