::ng-deep .mat-mdc-card {
  background: transparent;
  border: none !important;
}

.marked {
  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    color: #4072ee;
    text-decoration: initial;
    cursor: pointer;
  }
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.beta {
  margin: 3px 0 0 10px;
  background: #FEC400;
  font-size: 11px;
  padding: 2px 8px;
  border-radius: 10px;
  font-weight: 600;
  height: -moz-fit-content;
  height: fit-content;
  text-indent: 0;
  color: #313131;

  &.small {
    font-size: 10px;
    padding: 0 5px;
    margin: 0 0 0 10px;
  }

  &.black {
    background: #313131;
    color: #FEC400 ;
  }
}
