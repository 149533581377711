// We need to use ::ng-deep to overcome view encapsulation

.custom-slider {
  width: 100%;
}

.custom-slider .ngx-slider .ngx-slider-bar {
  //background: #ffe4d1;
  height: 10px;
  border-radius: 10px;
}

.custom-slider .ngx-slider .ngx-slider-span {
  font-size: 0.8rem;
  color: var(--main-text);
}

.custom-slider .ngx-slider .ngx-slider-pointer {
  width: 25px;
  height: 25px;
  background-color: #F4F4F4;
  border: rgba(49, 49, 49, 0.5) solid 2px;
  border-radius: 20px;
  margin-top: 6px;
}

.custom-slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.custom-slider .ngx-slider .ngx-slider-bubble {
  bottom: 14px;
}

//.custom-slider .ngx-slider .ngx-slider-limit {
//  font-weight: bold;
//  color: orange;
//}

.custom-slider .ngx-slider .ngx-slider-tick {
  //width: 1px;
  height: 10px;
  //border-radius: 0;
  background: #d8e0f3 !important;
  margin: 3px 0 0;
}

.custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: linear-gradient(to right, rgb(119, 119, 119) 0%, rgb(119, 119, 119) 100%) !important;
}

