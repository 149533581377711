.p-tabview {
  &.tab-panel-commands {
    .p-tabview-nav-container {
      .p-tabview-nav-content {
        border-radius: 10px 10px 0 0;
      }

      .p-tabview-nav {
        background: transparent;
        padding: 0;
        border: none;
        border-bottom: 2px solid var(--main-text-opacity-50);
        display: flex;
        gap: 2px;

        li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
          opacity: 0.6;
          background: var(--panel-content-sub-curl);
        }

        .p-highlight {
          .p-tabview-nav-link {
            box-shadow: none !important;
            font-weight: 600;
            border-radius: 5px 5px 0 0;
            //color: var(--main-text);
            border-color: #fec400;
            opacity: 1;
          }
        }

        .p-tabview-nav-link {
          color: white;
          font-weight: 400;
          opacity: 0.6;
          background: var(--panel-content-sub-curl);
          border-radius: 5px 5px 0 0;
          border: none;
          border-bottom: 2px solid #fff;
          text-decoration: none !important;
        }
      }
    }

    .p-tabview-panels {
      padding: 0;
      background: transparent;
      border-radius: 10px;
      position: relative;
      margin-top: 40px;
    }
  }

  &.tab-panel {
    .p-tabview-nav-container {
      .p-tabview-nav-content {
        border-radius: 10px 10px 0 0;

        .p-tabview-nav {
          background: transparent;
          border-bottom: 2px solid #FEC400;
          padding-bottom: 2px;

          li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
            color: var(--main-text);
            border-color: #FEC400;
            background: var(--panel-content-tab-active);
            font-weight: 500;
            border-radius: 20px 20px 0 0;
          }

          .p-highlight {
            .p-tabview-nav-link {
              background-color: var(--panel-content-tab-active);
              box-shadow: none !important;
              border-radius: 20px 20px 0 0;
            }
          }

          .p-tabview-nav-link {
            font-size: 0.8rem;
            font-weight: 400;
            color: var(--main-text);
            background-color: var(--app-bg);
            border-color: #FEC400;
            border-radius: 20px 20px 0 0;
            width: 16rem;
            justify-content: center;
            border-width: 0;

            &:hover {
              text-decoration: none;
            }

            &:not(.p-disabled):focus {
              box-shadow: none;
            }
          }
        }
      }
    }


    .p-tabview-panels {
      color: var(--panel-content-text);
      background-color: var(--app-bg);
    }
  }

  &.dropdown-tabView {
    .p-tabview-nav {
      background: transparent;


      li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        background: transparent;
      }

      .p-highlight {
        .p-tabview-nav-link {
          background: transparent;
          box-shadow: none !important;
          font-weight: 600;
          border-color: #fec400;
          color: var(--main-text);
        }
      }

      .p-tabview-nav-link {
        background: transparent;
        font-weight: 400;

        &:not(.p-disabled):focus {
          box-shadow: none;
        }
      }
    }

    .p-tabview-panels {
      background: transparent;
    }
  }
}
