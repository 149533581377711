.opster-calendar.p-calendar {
  min-width: 18rem;
  background: var(--app-bg);
  border-radius: 10px;

  .p-datepicker {
    padding-bottom: 0;
    border: none;
    background: var(--panel-content-sub-bg);
    color: var(--main-text);
    border-radius: 10px;

    table td {
      padding: 0.2rem;

      & > span {
        height: 2rem;
        width: 2rem;
      }
    }

    .p-timepicker {
      span {
        font-size: 1rem;
      }
    }

    .p-datepicker-header {
      padding: 0;
      background: transparent;
    }
  }

  .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: var(--main-text);
  }

  .p-inputtext {
    font-size: 0.8rem;
    border: none;

    &:enabled:focus {
      border: none;
      box-shadow: none;
    }
  }

  .p-datepicker-trigger {
    border-radius: 10px;
    background: var(--button-time-bg);
    color: var(--button-time-text);
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid var(--input-refresh-border);

    &:enabled:hover {
      background: var(--button-time-bg);
      color: var(--button-time-text);
      border: 1px solid var(--input-refresh-border);
      opacity: 0.6;
    }
  }
}

