.tabs-group {
  border-radius: 6px;
  //background-color: var(--panel-content-tabs);
  color: var(--main-text);
  //border-bottom: #fec400 5px solid;

  &.mat-mdc-tab-group {
    border-radius: 0;
  }

  .mat-mdc-tab-header {
    border-bottom: #fec400 5px solid !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-labels {
    justify-content: center;
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label {
      font-size: 0.8rem;
      font-weight: 400;
      opacity: 1;
      width: 100%;
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      &:hover:not(.mat-tab-disabled) {
        background: var(--panel-content-tab-active);
        border-radius: 20px 20px 0 0;
        /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-label-content {
          font-weight: 500;
        }
      }
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      &.mat-tab-disabled {
        opacity: 0.25;
      }
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label-active {
    background: var(--panel-content-tabs-settings);
    border-radius: 20px 20px 0 0;
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label-content {
      font-weight: 600;
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label-content {
    color: var(--main-text);
  }


  ul {
    padding-inline-start: 0;
  }

  .mat-ink-bar {
    display: none;
  }

}


/*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
::ng-deep .mat-card-command {
  background: #272822;
  padding: 0 !important;
  box-shadow: none !important;
  border-radius: 10px !important;

  a:-webkit-any-link {
    color: #2196f3;
  }
}

.link-dashboard {
  color: #4072ee;
}

.link-external {
  cursor: pointer;
}

.copy-small {
  height: 28px;
  width: 28px;
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;

  .copy-small-icon {
    height: 0.8rem;
    width: 0.8rem;

    ::ng-deep &.mat-icon {
      svg {
        path:nth-child(2) {
          fill: var(--main-text) !important;
        }
      }
    }

  }
}
