//::ng-deep .mat-checkbox.mat-accent.ops-checkbox {
//  .mat-checkbox-background {
//    background-color: var(--checkbox-bg);
//  }
//}


/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
::ng-deep .mat-mdc-checkbox.mat-accent.ops-checkbox.mat-checkbox-checked {
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-background {
    border: 1px solid #527ff0;
    background-color: var(--checkbox-bg);
    border-radius: 2px;
    /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-checkmark-path {
      stroke: white !important;
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
::ng-deep .mat-mdc-checkbox.mat-accent.ops-checkbox.mat-checkbox-indeterminate {
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-background {
    border: 1px solid #527ff0;
    background-color: var(--checkbox-bg);
    border-radius: 2px;
  }
}

::ng-deep .mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox.ng-star-inserted.mat-pseudo-checkbox-checked {
  border: 2px solid #527ff0;
  background-color: var(--checkbox-bg);
  border-radius: 2px;

  &:after {
    color: white !important;
  }
}

::ng-deep .mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox.ng-star-inserted {
  border-color: var(--checkbox-border);
}

// mat-checkbox
::ng-deep .mat-mdc-checkbox {
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-frame {
    border-color: rgba(255, 255, 255, 0.7);
  }
}


/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
::ng-deep .mat-checkbox-checked.mat-accent {
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-ripple .mat-ripple-element {
    background-color: #527ff0 !important;

  }
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #527ff0;
  }
}

::ng-deep .mat-mdc-checkbox.mat-accent {
  /*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-ripple .mat-ripple-element {
    background-color: #527ff0 !important;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
::ng-deep .mat-checkbox-disabled {
  opacity: 0.6;
}

.field-checkbox {
  //margin-bottom: 1rem;
  display: flex;
  align-items: center;

  .info-icon {
    width: 18px;
    height: 18px;
    transform: translateY(2px);
  }
}

.field-checkbox>label, .field-radiobutton>label {
  margin-left: 0.5rem;
  line-height: 1.5;
  font-weight: 400;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 5px;

  mat-icon {
    width: 0.8rem;
    height: 0.8rem;
    font-size: 0.8rem;
  }
}
