@import "../../../assets/common-sizes";



.icons {
  display: flex;

  p {
    margin-right: 20px;
    display: flex;
    justify-content: center;
    font-size: 17px;
    align-items: center;
  }


  .input-icon {
    width: 19px;
    height: 19px;
    margin-right: 6px;
  }
}

.action-buttons {
  display: grid;
  float: right;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  justify-content: flex-end;

  .btn-cu {
    border-radius: 6px;
    font-size: 0.8rem;
    font-weight: 500;
    border: none;
    width: auto;
    cursor: pointer;
    min-width: 90px;


    &.back {
      background: var(--button-cancel-bg);
      color: var(--button-cancel-text);
      font-weight: 600;
    }

    &.analyse {
      background: #fec400;

      &:hover:enabled {
        background: #f3a63b;
        color: white;
      }


    }
  }
}

.command {
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-gap: 1em;
  margin-bottom: 1rem;
  max-width: 550px;

  .box {
    border-radius: 10px;
    font-size: 0.8rem;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 14px;
    margin: 0;
    background: var(--panel-content-bg);
  }

  .copy-btn {
    border-radius: 10px;
    box-shadow: 0 3px 10px 0 rgba(22, 25, 28, 0.15);
    background-color: #fec400;
    display: grid;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #f3a63b;
    }

    .icon {
      height: 23px;
      width: 19px;
    }
  }
}

.end-2-v-es {
  font-size: 12px;
  font-weight: 500;
  color: #262c32;
  position: absolute;
  margin-top: -5px;
  opacity: 0.3;
  min-width: 400px;

  a {
    color: #262c32;
  }
}

.end-v-es {
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 2.14;
}

.v-es {
  font-size: 0.9rem;
  line-height: 1.67;
  font-weight: 400;
  margin-bottom: 0;

  &.big {
    font-size: 24px;
    font-weight: 600;
  }

  &.wizard-title {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;

  }

  .extracted {
    list-style: none;

    li {
      display: grid;
      grid-template-columns: 30px 1fr;
      margin-bottom: 10px;

      .mat-icon {
        height: 20px;
        width: 20px;
        font-size: 20px;
      }
    }
  }

}

.instructions {
  margin: 0 !important;
  cursor: pointer;
  color: #4A90E2;
  font-size: 0.9rem;
  font-weight: 600;
  text-decoration: underline;


  a {
    margin-left: 5px;
  }
}

.wizard-container {
  display: grid;
  grid-template-rows: 1fr 40px;
  grid-gap: 1em;
  height: 100%;

  .wizard-body-json {
    display: flex;
    flex-direction: column;

    .editor-input-container {
      position: relative;
      overflow-y: auto;
      height: 100%;
      border-radius: 10px;
      min-height: 260px
    }
  }
}

.wizard-benefits {
  font-size: 0.9rem;
  font-weight: 400;
  position: relative;
  height: 100%;
  display: grid;
  align-items: flex-end;



  .benefits-icon-panel {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: auto;
    width: auto;
  }

  .card {
    background: var(--panel-content-item-card-benefits);
    padding: 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: auto;
    border-radius: 20px;
    position: relative;
    height: 100%;

    &.card-img {
      background-image: url(https://opster.com/wp-content/uploads/2021/06/opster_create_account_2.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;

      .benefits-title {
        display: flex;
        align-items: center;

        .benefits-img-container {
          background: #fec4004d;
          height: 55px;
          width: 55px;
          border-radius: 55px;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          .benefits-img {
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--button-yellow-bg);
            height: 40px;
            width: 40px;
            border-radius: 40px;
          }
        }

      }
    }

    .card-footer {
      display: flex;
      text-align: right;
      align-items: flex-end;
      justify-content: flex-end;

      .gdpr-soc2 {
        width: 58px;
        height: 58px;

        &:first-child {
          margin-right: 25px;

        }
      }
    }

    .section {
      display: grid;
      grid-template-columns: 30px 1fr;
      align-items: center;

      mat-icon {
        height: 18px;
        width: 18px;
      }
    }
  }
}

.input-wizard {
  height: 100%;
  align-items: center;
  display: flex;
}

.wizard {
  height: 100%;
  display: flex;
  justify-content: center;
  max-height: 700px;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 8px 1px var(--panel-shadow);

  .sub-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.67;
    margin: auto;
    max-width: 680px;
    text-align: center;
  }


  .wizard-all {
    display: grid;
    grid-template-columns: 1fr 4fr;
    box-shadow: 3px 3px 8px 1px var(--panel-shadow);
    border-radius: 20px;
    width: 100%;
    max-width: 1200px;

    .wizard-steps {
      border-radius: 20px 0 0 20px;
      background-color: #313131;
      border-right: none;
      display: grid;
      justify-content: center;
      cursor: not-allowed;
      padding: 30px 10px;
    }

    .wizard-steps-mobile {
      display: none;
    }

    .wizard-inputs {
      border-radius: 0 20px 20px 0;
      padding: 20px 40px;
      color: var(--main-text);
      background: var(--app-bg);

      .wizard-inputs-switch {
        height: 100%;

        & > div {
          height: 100%;
        }

      }
    }
  }


}

.switch-case {
  height: 100%;
}


@media (max-width: $mobile-width) {


  .reviews-wizard {
    display: none !important;
  }


  .title, .v-es {
    font-size: 0.8rem;

    &.big {
      font-size: 24px;
      font-weight: 600;
    }


    &.step-cluster-settings {
      margin: 0 0 15px 0;
    }
  }

  .wizard-container {
    &.validate-error-container {
      grid-template-rows: 1fr 100px 40px !important;

    }

  }

  .end-2-v-es {
    font-size: 7px;
  }

  .form-report {
    .access-field {
      grid-template-rows: 33px 1fr;
      grid-template-columns: 1fr !important;
    }
  }
}

.validate-error {
  width: 100%;
  color: #EE6540;
  font-size: 0.8rem;
}
