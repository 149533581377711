
::ng-deep .darkTheme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: transparent linear-gradient(90deg, var(--panel-content-hover) 0%, #F4F4F400 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.panel-recommendations, .all-open-issues {
  height: inherit;

  .panel-container {
    background: var(--panel-content-bg) !important;
  }

  .mat-expansion-panel-header {
    padding: 0 12px 0 0;
    box-shadow: none !important;
    min-height: 72px;


    &.severity-HIGH {
      border-radius: 10px;

      ::ng-deep &.mat-expanded {
        background: transparent linear-gradient(90deg, var(--severity-high-ops) 0%, #FFC66A00 100%) 0% 0% no-repeat padding-box !important;
      }

      ::ng-deep .mat-content {
        &:hover {
          background: transparent linear-gradient(90deg, var(--severity-high-ops) 0%, #FFC66A00 100%) 0% 0% no-repeat padding-box;
        }
      }
    }

    &.severity-MEDIUM {
      border-radius: 10px;

      ::ng-deep &.mat-expanded {
        background: transparent linear-gradient(90deg, var(--severity-medium-ops) 0%, #FFC66A00 100%) 0% 0% no-repeat padding-box !important;
      }

      ::ng-deep .mat-content {
        &:hover {
          background: transparent linear-gradient(90deg, var(--severity-medium-ops) 0%, #FFC66A00 100%) 0% 0% no-repeat padding-box;
        }
      }
    }

    &.severity-LOW {
      border-radius: 10px;

      ::ng-deep &.mat-expanded {
        background: transparent linear-gradient(90deg, var(--severity-low-ops) 0%, #FFC66A00 100%) 0% 0% no-repeat padding-box !important;
      }

      ::ng-deep .mat-content {
        &:hover {
          border-radius: 10px;
          background: transparent linear-gradient(90deg, var(--severity-low-ops) 0%, #FFC66A00 100%) 0% 0% no-repeat padding-box;
        }
      }
    }

    &.close {
      &.severity-HIGH {
        border-radius: 10px;

        ::ng-deep &.mat-expanded {
          background: transparent linear-gradient(90deg, var(--severity-high-ops) 0%, #31313100 100%) 0% 0% no-repeat padding-box !important;
        }

        ::ng-deep .mat-content {
          &:hover {
            border-radius: 10px;
            background: transparent linear-gradient(90deg, var(--severity-high-ops) 0%, #31313100 100%) 0% 0% no-repeat padding-box;
          }
        }
      }

      &.severity-MEDIUM {
        border-radius: 10px;

        &.mat-expanded {
          background: transparent linear-gradient(90deg, var(--severity-medium-ops) 0%, #31313100 100%) 0% 0% no-repeat padding-box !important;
        }


        ::ng-deep .mat-content {
          &:hover {
            border-radius: 10px;
            background: transparent linear-gradient(90deg, var(--severity-medium-ops) 0%, #31313100 100%) 0% 0% no-repeat padding-box;
          }
        }
      }

      &.severity-LOW {
        border-radius: 10px;

        &.mat-expanded {
          background: transparent linear-gradient(90deg, var(--severity-low-ops) 0%, #31313100 100%) 0% 0% no-repeat padding-box !important;
        }

        ::ng-deep .mat-content {
          &:hover {
            border-radius: 10px;
            background: transparent linear-gradient(90deg, var(--severity-low-ops) 0%, #31313100 100%) 0% 0% no-repeat padding-box;
          }
        }
      }

      &.mat-expanded {
        &:before {
          box-shadow: none;
        }
      }
    }


    ::ng-deep .mat-content {
      &:hover {
        .sliders-horiz {
          display: block !important;
          transform: translateX(0);
        }
      }

    }


    ::ng-deep .mat-expansion-panel-header-description {
      margin-right: 0;
    }


  }

}
