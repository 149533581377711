
::ng-deep .mat-slide-toggle-bar {
  background: var(--slide-toggle) !important;
  border: none !important;
}

::ng-deep .mat-slide-toggle-thumb-container {
  z-index: 0 !important;
}

::ng-deep .toggle-bar-menu {
  .mat-slide-toggle-bar {
    background: rgba(255, 255, 255, 0.5) !important;
    border: none !important;
  }

  .mat-slide-toggle-thumb {
    background-color: #FFFFFF !important;
  }
}


::ng-deep .mat-mdc-slide-toggle {
  .mdc-form-field {
    .mdc-label {
      font-size: 12px;
      font-weight: 500;
      color: var(--main-text);
    }
  }

  &.toggle-enabled {
    .mdc-switch__shadow {
      background: #fafafa;
    }

    .mdc-switch__track {
      &::after {
        background: #29CB97 !important;

      }
    }
  }

  &.toggle-sidemenu {
    .mdc-switch__shadow {
      background: #fafafa;
    }

    .mdc-switch__track {
      &::after {
        background: #989898 !important;
      }
    }
  }


}


