@import "../../../assets/common-sizes";

p-carousel {
  ::ng-deep .p-carousel {
    &.reviews-carousel {
      .p-carousel-prev {
        display: none !important;
      }

      .p-carousel-next {
        display: none !important;
      }

      .p-carousel-indicators {
        justify-content: start;
      }

      .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        background: #313131;
      }

      .p-carousel-indicators .p-carousel-indicator button {
        border-radius: 20px;
        width: 10px;
        height: 10px;
      }
    }

    &.event-cards-carousel {
      height: 100%;


      .p-carousel-content {
        height: 100%;
        justify-content: center;

        .p-carousel-container {
          height: 100%;
          align-items: center;

          .p-carousel-next {
            right: 0;
          }

          .p-carousel-prev, .p-carousel-next {
            background: var(--card-count-bg);
            margin: 0.1rem;
            position: absolute;
            z-index: 2;

            &.p-disabled {
              display: none;
            }

            &:focus {
              box-shadow: none;
              border: none;
            }
          }

          .p-carousel-items-content {
            height: 100% !important;
            max-height: 160px;
            position: absolute;

            .p-carousel-items-container {
              height: 100%;
              padding-right: 41px;

              .p-carousel-item {
                margin-right: 10px;

              }
            }
          }
        }


      }
    }
  }
}


::ng-deep .container-user {
  display: grid;
  grid-template-columns: 3fr 1fr;
  height: 100%;

  ::ng-deep .p-carousel-item {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }


  .right-trusted-by {
    display: grid;
    grid-template-rows: 60px 60px;
  }

}

.p-avatar {
  &.review-avatar {
    width: 48px;
    height: 48px;

    img {
      width: 48px;
      height: 48px;
    }
  }
}

.p-carousel {
  &.reviews-carousel {
    max-width: 350px;
  }
}

@media all and (max-width: $xl) {
  .p-carousel {
    &.reviews-carousel {
      max-width: 300px;
    }
  }
}

@media all and (max-width: $lg) {
  .p-carousel {
    &.reviews-carousel {
      max-width: 250px;
    }
  }
}

@media all and (max-height: $sm-height) {
  .p-carousel {
    &.reviews-carousel {
      .p-carousel-indicators {
        display: none;
      }
    }
  }
}

@media all and (min-width: $xl-screen-width) {
  p-carousel {
    ::ng-deep .p-carousel {
      &.event-cards-carousel {
        .p-carousel-content {
          .p-carousel-container {
            .p-carousel-items-content {
              .p-carousel-items-container {
                padding-right: 50px;
              }
            }
          }
        }
      }
    }
  }
}
