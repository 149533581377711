@import '../../src/app/shared/style/style';


::ng-deep .mat-mdc-progress-spinner circle, .mat-mdc-progress-spinner circle {
  stroke: var(--spinner-color) !important;
}

.link {
  color: #4072ee;
  text-decoration: initial;
  cursor: pointer;

  &.mail {
    color: var(--main-text);
    text-decoration: underline;
  }

  &:hover {
    text-decoration: underline;
  }

  &.no-hover {
    &:hover {
      text-decoration: none;
    }
  }
}


.disabled {
  opacity: 0.6;
  pointer-events: none;
}
