::ng-deep mat-icon {
  line-height: 1;

  svg {
    pointer-events: none;
  }
}

::ng-deep .refresh_btn {
  svg {
    circle {
      fill: var(--icon-circle) !important;
    }

    path {
      fill: var(--main-text) !important;
    }

  }
}

::ng-deep .chevron_right {
  ::ng-deep &.mat-icon {
    height: 14px;
    line-height: 0.5;

    svg path {
      fill: var(--main-text);
    }
  }

  &.menu {
    svg path {
      fill: #fff;
    }
  }
}

.icon-search {
  cursor: pointer;
  opacity: 0.5;
  line-height: 1;
  height: 18px;
  width: 18px;

  ::ng-deep &.mat-icon {
    svg path {
      fill: var(--main-text) !important;
    }
  }
}

.sliders-horiz {
  background: var(--button-settings-bg) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  margin-left: 10px;
  border-radius: 3px;
  width: 30px;
  height: 26px;
  padding: 3px;

  ::ng-deep svg path {
    fill: var(--button-settings-fill);
  }

}

.global-icon {
  ::ng-deep &.mat-icon {
    svg {
      path {
        fill: var(--main-text);
      }
    }
  }
}

::ng-deep .icon-delete {
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin-right: 10px;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &.margin-left {
    margin-left: 10px;
  }

  ::ng-deep svg path:nth-child(2) {
    fill: var(--icon-color) !important;

  }

  &.dismiss {
    ::ng-deep svg path {
      fill: var(--icon-color) !important;
    }
  }


  &:hover {
    opacity: 0.8;
  }
}

.copy-line {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  cursor: pointer;
  font-size: 12px;
  text-align: right;

  &.on-tab-view {
    position: absolute;
    top: -35px;
    right: 0;
    width: max-content;
  }

  .item-copy-line.no-padding-bottom {
    transform: translateY(45px);
    z-index: 10;
  }

  .item-copy-line {
    cursor: pointer;
    color: var(--main-text);

    .copy-line-icon {
      width: 14px;
      height: 14px;
      line-height: 1;


      ::ng-deep &.mat-icon {
        svg {
          path:nth-child(2) {
            fill: var(--main-text) !important;
          }
        }
      }


    }
  }


  .copy-line-icon {
    width: auto;
    max-width: 40px;
  }
}


.opster-chat {
  width: 5rem;
  height: 5rem;

  &.small {
    margin-top: 0.5rem;
    width: 2rem;
    height: 2rem;
  }
}
