::ng-deep .p-chip .p-chip-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
}

.p-chip {
  &.selected-clusters-chip {
    border-radius: 16px;
    border: solid 1px rgba(49, 49, 49, 0.15);
    background-color: var(--cluster-chip-background);
    opacity: 0.7;
    font-size: 12px;
    font-weight: 500;
    color: var(--cluster-chip-text-color);

    &:hover {
      opacity: 1;
    }
  }
}

.p-chips {
  width: 100%;

  &.opster-chips {
    margin: 20px 0;

    .p-chips-multiple-container {
      width: 100%;
      background: var(--app-bg);
      border-color: var(--border);
      color: var(--main-text);

      &:not(.p-disabled).p-focus {
        box-shadow: none;
      }

      .p-chips-token {
        border-radius: 16px;
        border: solid 1px rgba(49, 49, 49, 0.15);
        background-color: var(--chip-background);
        opacity: 0.7;
        font-weight: 500;
        color: var(--cluster-chip-text-color);

        .p-chips-token-label {
        }
      }

      .p-chips-input-token {
        input {
          font-family: 'Poppins', sans-serif;
          color: var(--main-text);
        }
      }
    }
  }
}
