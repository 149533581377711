::ng-deep .template-optimizer-editor {
  div {
    height: 100%;
  }

  .jsoneditor-search {
    height: 25px;
  }
}

.add-end-point-editor {
  height: 100%;
  .jsoneditor-outer {
    padding: 5px;
  }
  .ace-jsoneditor {
    border-radius: 10px;
    min-height: 240px;
  }
}

::ng-deep .editor-input > div {
  height: 100%;
}

::ng-deep .jsoneditor {
  border-radius: 10px;
  background: #2a2c32;
  border: none;

  // main bg
  .ace_content {
    background: #2a2c32 !important;
  }

  .ace_gutter {
    background: #2a2c32 !important;
    border-right: 1px solid #6b6b71;

  }

  //// selection
  .ace_active-line,
  .ace_gutter-active-line {
    background: #3f4247 !important;
  }

  .ace_gutter-cell {
    color: grey !important;
  }

  .ace_line {
    color: white !important;
  }


  .jsoneditor-poweredBy {
    display: none !important;
  }


  .jsoneditor-menu {
    height: 35px;
    background-color: rgba(31, 35, 39, 0.6);
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .jsoneditor-tree,
  .jsoneditor textarea.jsoneditor-text {
    color: #ffffff;
  }

  .jsoneditor-tree {
    background: #2a2c32 !important;
  }

  .jsoneditor-field,
  .jsoneditor-value {
    color: #ffffff;
    pointer-events: none;
  }

  .jsoneditor-search .jsoneditor-frame {
    background: #808080;
    border-radius: 10px;
    padding: 0 5px;

    input {
      background: #808080;
    }
  }

  tr.jsoneditor-highlight,
  tr.jsoneditor-selected {

    background-color: #2a2c32;

    .jsoneditor-values {
      .jsoneditor-tree {
        background-color: rgb(117 190 248 / 20%) !important;
      }
      .jsoneditor-separator {
        background-color: rgb(117 190 248 / 20%) !important;
      }
    }

  }

  .jsoneditor-field[contenteditable=true]:focus,
  .jsoneditor-field[contenteditable=true]:hover,
  .jsoneditor-value[contenteditable=true]:focus,
  .jsoneditor-value[contenteditable=true]:hover,
  .jsoneditor-field.jsoneditor-highlight,
  .jsoneditor-value.jsoneditor-highlight {
    //background-color: #808080;
    //border-color: #808080;
  }

  .jsoneditor-field.highlight-active,
  .jsoneditor-field.highlight-active:focus,
  .jsoneditor-field.highlight-active:hover,
  .jsoneditor-value.highlight-active,
  .jsoneditor-value.highlight-active:focus,
  .jsoneditor-value.highlight-active:hover {
    background-color: #b1b1b1;
    border-color: #b1b1b1;
  }

  .jsoneditor-tree button:focus {
    background-color: #868686;
  }

  .jsoneditor-readonly {
    color: #acacac;
  }

  .jsoneditor td.jsoneditor-separator {
    color: #acacac;
  }

  .jsoneditor-value.jsoneditor-string {
    color: #9cb977;
  }

  .jsoneditor-value.jsoneditor-object,
  .jsoneditor-value.jsoneditor-array {
    color: #bababa;
  }

  .ace_variable, ::ng-deep .jsoneditor-field {
    color: #ac7ba8 !important;
  }

  .ace_string {
    color: #9cb977 !important;
  }

  .jsoneditor-value.jsoneditor-number {
    color: #49a7fc !important;
    width: 400px;
  }

  .ace_numeric {
    color: #5fa0bd !important;
  }

  .jsoneditor-value.jsoneditor-boolean {
    color: #ff8048;
  }

  .jsoneditor-value.jsoneditor-null {
    color: #ff4040;
  }

  .jsoneditor-value.jsoneditor-invalid {
    color: white;
  }

  .jsoneditor-value.jsoneditor-string {
    color: #d7b55d !important;
  }

  // footer
  .jsoneditor-statusbar {
    background: #202227;
    border: none;
    border-radius: 0 0 10px 10px;
    padding-left: 5px;
  }

  // header
  .jsoneditor-menu {
    border: none;
    background: #202227;
    border-radius: 10px 10px 0 0;
  }


  .jsoneditor-button:focus {
    background-color: transparent !important;
    outline: none !important;
  }

  .jsoneditor table, .jsoneditor-modal table {
    border-collapse: collapse !important;
    width: auto !important;
  }

  .jsoneditor-value.jsoneditor-object, .jsoneditor-value.jsoneditor-array {
    min-width: 50px !important;
  }

  .jsoneditor-boolean {
    min-width: 50px !important;
  }


}


