.user-form {
  display: inline-grid;
  margin: 20px 0 50px 0;
  width: 100%;
  gap: 40px;
  padding: 15px 0;

}

.field-form {
  display: grid;
  grid-template-columns: 250px auto;
  margin-bottom: 10px;
  align-items: center;

  &.space {
    gap: 2rem
  }

  &.small {
    grid-template-columns: min-content 300px;
    grid-gap: 20px;

  }
}
