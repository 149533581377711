
.serieName {
  float: left;
  cursor: pointer;
}


::ng-deep .mat-mdc-tooltip {
  background-color: #495057 !important;
  color: #fff !important;
  padding: 8px !important;

  .mdc-tooltip__surface {
    background-color: #495057 !important;
  }
}

::ng-deep .tooltip-custom {
  max-width: none !important;
  background-color: var(--tooltip-bg) !important;
  color: var(--main-text) !important;

  ::ng-deep &::after {
    border-color: transparent !important;
  }

}

.p-tooltip {
  width: 100%;
  z-index: 9999999999 !important;

  &.selected-date-tooltip {
    .p-tooltip-text {
      .range-date {
        text-align: center;
      }
    }
  }

  &.multiselect-tooltip {
    max-width: 15rem;

    &.auto-width {
      max-width: unset;
    }

    .p-tooltip-text {
      border-radius: 10px;
      width: 100%;
      min-width: 150px;

      .selectedItems-ul {
        padding: 0 5px 0 20px;
        margin: 0;
      }

      .item-tooltip {
        list-style: none;
        padding: 0 5px 0 5px;
        margin: 5px;

        li {
          gap: 10px;
          display: flex;
          align-items: center;
        }

        .symbol {
          min-width: 10px;
          height: 10px;
          float: left;
          -webkit-border-radius: 10px;
          border-radius: 10px;
          z-index: 10;
        }
      }

    }
  }

  &.account-tooltip {
    width: 100%;
    height: fit-content;

    .p-tooltip-text {
      border-radius: 10px;
      box-shadow: 0 7px 15px -5px #0006;
      width: fit-content;
      height: fit-content;
      display: grid;

      .account-tooltip-body {
        display: grid;
        grid-template-columns: auto 3fr;
        gap: 10px;
        justify-content: center;
        align-items: center;
        padding: 5px 20px;
        width: 100%;

        .side-menu-icon {
          display: block;
          height: 22px;
          width: 22px;
          color: var(--side-menu-text);

          &.short-name {
            border-radius: 25px;
            border: 1px solid #FEC400;
            width: 30px;
            height: 30px;
            color: #FEC400;
            font-size: 0.8rem;
            font-weight: 600;
            line-height: 2;
            display: flex;
            justify-content: inherit;
            align-items: flex-end;
          }


          svg g {
            stroke: none;
          }
        }


        .right {
          display: grid;
          font-weight: 400;
          font-size: 0.9rem;
          width: 100%;

          .email {
            font-weight: 600;
            border-bottom: 1px solid #707070;
            white-space: nowrap;
            text-align: center;
          }

          .company {
            white-space: nowrap;
          }
        }
      }

    }
  }

  &.tooltip-subpanel {
    max-width: 15rem;

    .p-tooltip-text {
      font-size: 0.8rem;
    }
  }

  &.cluster-card {
    width: auto;
    display: inline-flex;

    .p-tooltip-text {
      display: inline-flex;
    }
  }

  &.hovered-icon {
    .p-tooltip-text {
      word-break: normal;
      width: inherit;

    }
  }

  &.hovered-icon-long {
    .p-tooltip-text {
      white-space: nowrap;
    }
  }
}
