@import './assets/common';
@import '../src/app/shared/style/style';
@import 'styles/material-theme';
@import "~material-design-icons/iconfont/material-icons.css";

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display:swap;
}

::ng-deep .mat-icon:not([fontSet]) {
  display: none;

  .loaded & {
    // loaded font successfully
    display: inline-block;
  }

  .error & {
    // could not load font - show fallback label if available
    display: inline-block;
    visibility: hidden;

    &[data-label]:before {
      content: attr(data-label);
      visibility: visible;
    }
  }
}

@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-display:swap;
}

@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-display:swap;
}

@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-display:swap;
}

@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-display:swap;
}

html, body {
  margin: 0;
  height: 100%;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1rem;
}

body .p-component {
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: var(--app-bg);
  color: var(--main-text) !important;
  padding: 0;
  margin: 0;
}

h1 {
  font-weight: 600;
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
}

.mat-mdc-focus-indicator::before, .mat-focus-indicator::before {
  border: none;
}

#g_a11y_announcement {
  height: 0 !important;
  width: 0 !important;
}

//@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Poppins:wght@600&family=Poppins:wght@500&family=Poppins:wght@400&display=swap');
//@import url('https://fonts.googleapis.com/icon?family=Material+Icons');




