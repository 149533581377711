.opster-video {
  position: relative;
  z-index: 1;
  display: none;
  justify-content: center;
  align-items: center;

  &.settings {
    width: auto;
  }

  &.loaded {
    display: flex;
  }

  .img-cover {
    width: 38vw;
    height: auto;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 8px 0 rgb(0 0 0 / 30%);

    &.small {
      width: 10vw;
      min-width: 200px;
    }
  }

  .play-text-overlay {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover .play-btn:before {
      width: 40px;
      height: 40px;
    }

    .play-btn {
      width: 90px;
      height: 90px;
      margin: 0 auto;
      position: relative;

      &:before {
        content: '';
        display: block;
        background: #545454 url("https://mlbtihvv1ztx.i.optimole.com/mlbtihvv1ztx-opster-oz07e9d/w:auto/h:auto/q:auto/https://opster.com/wp-content/themes/opster/images/play-button-white-icon.svg") no-repeat 60% center;
        border: 5px solid rgba(255, 255, 255, .5);
        background-size: 40%;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: all ease-out .2s;
      }
    }

    .play-text {
      margin: 10px 0 0;
      padding: 0 10px;
      color: #FFFFFF;
      font-size: 0.9rem;
      background: #545454;
      border-radius: 15px;
      line-height: 34px;
    }
  }
}

.tour-wrapper {
  font-weight: 500;
  display: flex;
  gap: 6px;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;

  .play-icon {
    overflow: initial;
  }

  .play-text {
    display: flex;
    flex-direction: column;

    .first-line {
      font-size: 0.7rem;
    }

    .second-line {
      font-size: 0.6rem;
      color: var(--button-video-play-line-timestamp);
    }
  }
}
