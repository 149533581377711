.p-password {
  width: 100%;
  &.opster-password {
    input {
      width: 100%;
      -webkit-text-fill-color: white;
      caret-color: white;

      &.p-inputtext {
        &:enabled:focus {
          -webkit-box-shadow: 0 0 0 0.2rem #8ad0ff9c;
          box-shadow: 0 0 0 0.2rem #8ad0ff9c;
        }
      }
    }
  }
}
