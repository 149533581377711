.editor-command.ace_editor.ace_dark.ace-monokai {
  background-color: var(--panel-content-sub-curl);
  border-radius: 20px;
  color: #A2B77E;
  caret-color: #A2B77E;

  .ace_gutter {
    color: #A2B77E;
    background-color: var(--panel-content-sub-curl);

    .ace_folding-enabled > .ace_gutter-cell {
      background-color: var(--panel-content-sub-curl);
      opacity: 0.5;

    }
  }
}

