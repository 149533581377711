
.empty-state-panel, .empty-state {
  display: grid;
  height: 100%;
  margin: 0;

  .mat-icon {
    width: 5vw;
    height: auto;
    margin: auto;
  }

  .empty-state-text {
    text-align: center;
    font-size: 18px;
    font-weight: 600;

    p:first-child {
      font-weight: normal;
    }
  }
}

.wrapper-spinner {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 100%;

  .empty-state-full {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    &.space {
      padding: 10px;
    }
  }
}
