@import "../../../assets/common-sizes";

$dashboard-table-thead-height: 38px;
$dashboard-table-scroll: 10px;

::ng-deep .mat-mdc-table {
  background: transparent !important;
  width: 100%;

  .mat-mdc-cell, .mat-mdc-header-cell {
    border-color: var(--border)
  }

  .mat-mdc-header-row {
    font-size: 12px;
    color: var(--table-header-text);
  }

  .mdc-data-table__content {
    .mat-mdc-row {
      color: var(--main-text);

      &.inactive {
        color: var(--main-text-opacity-50);
      }
    }
  }
}

::ng-deep .setting-table {
  margin-bottom: 100px;

  tbody {
    tr {
      &:hover {
        background: transparent linear-gradient(90deg, var(--panel-content-hover) 0%, #F4F4F400 100%) 0% 0% no-repeat padding-box;
      }
    }

  }

  .mat-mdc-table {
    .mat-mdc-cell {
      padding-right: 15px;
      background: transparent !important;
    }
  }
}

.tokens-table {
  th.mat-mdc-header-cell:nth-of-type(2), td.mat-mdc-cell:nth-of-type(2) {
    width: 45%;
  }
}

//primeng table
.table-shards-view {
  height: inherit;

  .p-datatable.p-datatable-gridlines {

    .p-datatable-loading-overlay {
      display: none;
    }

    .p-datatable-header {
      padding: 0;
      background-color: var(--panel-content-table-group);
      color: var(--toolbar-header-text);
      border-radius: 20px 20px 0 0;
      border: none;
      margin-right: -8px;

      tr {
        display: flex;
        width: 100%;

        .shards-view-column.header {
          display: flex;
          flex: 1 1 0;
          align-items: center;
          border: 1px solid var(--input-dropdown-search-bg);
          justify-content: center;
        }
      }
    }

    .p-datatable-table {
      position: absolute;
      height: 100%;
      font-size: 0.9rem;

      .p-datatable-tbody {
        display: block;

        .p-rowgroup-header {
          background: var(--panel-content-table-group);

          .group-header {
            background: var(--panel-content-table-group);
          }
        }

        & > tr {
          display: flex;
          flex-wrap: nowrap;
          width: 100%;
          background: transparent;
        }

        & > tr > td {
          display: flex;
          align-items: center;
          flex: 1 1 0;
          background: var(--panel-content-item);
          color: var(--toolbar-header-text);
          border-color: var(--input-dropdown-search-bg);
          border-width: 1px !important;
        }
      }

      .p-datatable-thead {
        z-index: 2;
        background-color: transparent;

        & > tr {
          display: flex;
          flex-wrap: nowrap;
          width: 100%;

          > th {
            background: var(--panel-content-item);
            color: var(--toolbar-header-text);
            border-color: var(--input-dropdown-search-bg);

            &:first-child {
              //border-radius: 20px 0 0 0;
            }

            &:last-child {
              //border-radius: 0 20px 0 0;
            }
          }
        }
      }

      .group-header {
        background-color: rgba(244, 244, 244, 0.3);
        padding: 0.5rem;

        span {
          font-size: 12px;
          font-weight: 500;
          font-style: italic;
          letter-spacing: -0.3px;
          color: var(--toolbar-header-text);
        }

      }
    }

    .p-datatable-wrapper {
      background: transparent;
      margin-right: -8px;
      overflow-y: scroll;

      .p-datatable-flex-scrollable {
      }
    }

  }
}

.chat-table {
  height: inherit;
  font-size: 0.8rem;
  position: absolute;
  padding: 10px;

  .p-datatable.chat-history-table {
    border-radius: 0;
    min-width: 420px;

    .p-datatable-table {
      table-layout: fixed;

      .p-datatable-tbody > tr {
        font-size: 0.8rem;
        cursor: pointer;
        color: var(--panel-content-text);
        background: var(--panel-content-item-card);
        border-color: var(--panel-content-item);

        td {
          border-bottom: 1px solid var(--input-dropdown-search-bg);
        }

        &:hover {
          background: transparent linear-gradient(90deg, var(--panel-content-hover) 0%, #F4F4F400 100%) 0% 0% no-repeat padding-box;

          td {
            padding: 0.5rem;

            .icon-delete {
              display: block !important;
              transform: translateX(0);
              margin-left: 8px;
              min-width: 20px;
              min-height: 20px;
            }
          }
        }
      }

      .p-datatable-thead {
        border-radius: 20px;
        height: 50px;
      }

      .p-datatable-thead > tr > th {
        font-size: 12px;
        color: var(--panel-content-text);
        background: var(--panel-content-item-card);
        border-color: var(--input-dropdown-search-bg);
        padding: 8px;
        white-space: pre-line;
        overflow: hidden;
        text-overflow: ellipsis;
        position: sticky;
        top: 0;
        z-index: 1;

        &:first-child {
          //border-radius: 10px 0 0 10px;
        }

        &:last-child {
          justify-content: center;
        }

        &.p-sortable-column {
          &.p-highlight {
            color: #4B81F5;
          }

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.dashboard-table {
  height: inherit;
  position: absolute;
  width: 100%;

  ::ng-deep sortalticon {
    display: none;
  }

  .p-datatable.top-notifications-table {
    .p-datatable-table {
      min-width: 550px;

      .p-datatable-tbody {

        > tr {
          height: 2.4rem !important;
        }
      }
    }
  }

  .p-datatable.clusters-info-table {
    .p-datatable-table {
      min-width: 900px;
    }
  }

  .p-datatable.notifications-history-table {
    .p-paginator {
      font-size: 12px;
      background: transparent;
      border: none;
      flex-wrap: nowrap;
      padding: 0;
      justify-content: flex-end;
      margin-right: 3rem;

      .p-paginator-current {
        color: var(--main-text);
      }

      .p-paginator-first,
      .p-paginator-prev,
      .p-paginator-next,
      .p-paginator-last {
        &:not(.p-disabled):not(.p-highlight):hover {
          background: var(--dropdown-hover);
          border: var(--grid-line-opacity);
          border-radius: 10px;
        }

        &.p-link:focus {
          box-shadow: none;
        }

        &:hover {
          span {
            color: var(--table-button-icon-hover);
          }
        }
      }

      .p-paginator-pages {
        display: flex;
        flex-wrap: nowrap;

        .p-paginator-page {
          font-size: 12px;
          color: var(--main-text);
          border-radius: 10px;
          opacity: .6;

          &.p-highlight {
            opacity: 1;
            font-size: 0.8rem;
            background: transparent;
            font-weight: bold;
            border: none;

            &:hover {
              background: var(--dropdown-hover);
              border: var(--grid-line-opacity);
            }
          }

          &:not(.p-highlight) {
            color: var(--main-text);

            &:hover {
              opacity: 1;
              background: var(--dropdown-hover);
              border: var(--grid-line-opacity);
            }
          }
        }

        .p-link:focus {
          box-shadow: none;
        }
      }

      .p-paginator-rpp-options {

        .p-dropdown-panel {
          background: var(--input-search-bg);
          color: var(--main-text);

          .p-dropdown-items {
            .p-dropdown-item {
              color: var(--main-text);

              &.p-highlight {
                background: var(--dropdown-selected);
                color: #31394d;
              }

              &:not(.p-highlight):not(.p-disabled):hover {
                background: var(--dropdown-hover);
              }
            }
          }
        }

        &.p-dropdown {
          background: transparent;
          border: none;
          border-bottom: 1px solid #f9c400;
          border-radius: 0;

          &:not(.p-disabled) {
            &.p-focus {
              box-shadow: none;
            }
          }

          .p-dropdown-label {
            font-size: 0.8rem;
            color: var(--main-text);
          }
        }
      }
    }
  }

  .p-datatable.notifications-history-table,
  .p-datatable.clusters-info-table,
  .p-datatable.top-notifications-table {
    min-height: 100px;
    height: inherit;

    .p-datatable-wrapper {
      height: unset;
    }

    .p-datatable-table {
      border-collapse: separate;
      //border-spacing: 0 6px;
      height: inherit;

      .p-datatable-thead {
        height: $dashboard-table-thead-height;
        background: transparent;

        > tr {
          display: flex;

          > th {
            font-size: 0.75rem;
            font-weight: 500;
            background: var(--panel-table-bg);
            padding: 0.625rem 0.3rem;
            white-space: nowrap;
            color: var(--main-sub-text-light);
            border-width: 0;
            position: sticky;
            top: 0;
            z-index: 1;

            // CLUSTERS TABLE
            &.cluster-alias {
              flex: 0 0 250px;
            }

            &.cluster-status {
              flex: 1 0 65px;
            }

            &.number-of-critical-events {
              flex: 1 0 112px;
            }

            &.number-of-nodes {
              flex: 1 0 65px;
            }

            &.number-of-shards {
              flex: 1 0 65px;
            }

            &.notifications-sent-clusters {
              flex: 1 0 133px;
            }

            &.cluster-cost {
              flex: 1 0 108px;
            }

            &.potential-savings {
              flex: 1 0 130px;
            }

            &.menu {
              flex: 1 0 25px;
            }

            &:first-child {
              padding-left: 1.25rem;
            }

            // TOP NOTIFICATIONS TABLE
            &.event-name {
              flex: 0 0 250px;
            }

            &.notifications-sent {
              flex: 1 0 116px;
              text-align: center;
            }

            &.no-clusters {
              flex: 1 0 82px;
              text-align: center;
            }

            &.failed-to-send {
              flex: 1 0 95px;
              text-align: center;
            }

            // NOTIFICATIONS HISTORY TABLE
            &.notification-time {
              flex: 0 0 180px;
            }

            &.event-title {
              flex: 1 1 250px;
            }

            &.event-open-time {
              flex: 0 0 180px;
            }

            &.cluster-title {
              flex: 1 1 200px;
            }

            &.notification-status {
              flex: 0.5 1 180px;
            }

            &.endpoint-name {
              flex: 0.5 1 150px;
            }

            &.p-sortable-column {
              &:focus {
                box-shadow: none;
              }

              p-sorticon {
                .p-sortable-column-icon {
                  color: currentColor;
                  font-size: 12px;
                  margin: 0;
                  transform: translateX(4px);

                  &.pi-sort-alt:before {
                    content: '';
                  }

                  &.pi-sort-amount-up-alt:before {
                    content: '\e91c';
                  }

                  &.pi-sort-amount-down:before {
                    content: '\e919';
                  }
                }
              }
            }
          }
        }
      }

      .p-datatable-tbody {
        height: calc(100% - $dashboard-table-thead-height);
        width: calc(100% - $dashboard-table-scroll);
        overflow-y: auto;
        overflow-x: hidden;

        > tr {
          display: flex;
          font-size: 0.8rem;
          height: 3rem;
          border-radius: 10px;
          background: var(--table-row-active-bg);
          margin-bottom: 6px;

          &.no-border {
            > td, td:first-child, td:last-child {
              border-width: 0;
            }
          }

          &.inactive {
            background: var(--table-row-inactive-bg);
          }

          &:hover {
            td {
              border-color: var(--table-border-color-hover);
            }
          }

          > td {
            display: flex;
            align-items: center;
            padding: 0.1rem;
            color: var(--main-text);
            border: solid var(--table-border-color);
            border-width: 1px 0;

            // CLUSTERS TABLE
            &.cluster-alias {
              flex: 0 0 250px;

              .dashboard-copy-icon {
                visibility: hidden;
                width: 0.8rem;
                height: 0.8rem;
                margin-left: 5px;
                cursor: pointer;
              }

              .double-values {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                height: 100%;
                width: 100%;

                .clusterAlias-wrapper {
                  display: flex;
                  align-items: center;
                  line-height: 20px;
                  max-width: 100%;

                  .clusterAlias {
                    font-size: 0.8rem;
                    max-width: 91%;
                    white-space: nowrap;
                    color: var(--info-card-text-link);
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  &:hover {
                    .dashboard-copy-icon {
                      visibility: visible !important;
                    }
                  }
                }

                .clusterId {
                  font-size: 0.6rem;
                  line-height: 20px;
                  white-space: nowrap;
                  letter-spacing: -0.5px;
                  color: var(--info-card-sub-dark-gray);

                  &:hover {
                    .dashboard-copy-icon {
                      visibility: visible !important;
                    }
                  }
                }
              }
            }

            &.cluster-status {
              flex: 1 0 65px;

              .status-light-outer {
                margin-left: 1rem;
                border: 0.8px solid #D8D8D8;
                border-radius: 50%;
                width: 1.25rem;
                height: 1.25rem;
                display: flex;
                align-items: center;
                justify-content: center;

                .status-light-inner {
                  border-radius: 50%;
                  width: 1rem;
                  height: 1rem;
                  transform: scale(0.9);

                  &.GREEN {
                    background: #27CA40;
                  }

                  &.YELLOW {
                    background: #FFC130;
                  }

                  &.RED {
                    background: #FF492C;
                  }

                  &.INACTIVE {
                    background: #7E7E7E;
                  }
                }
              }
            }

            &.number-of-critical-events {
              flex: 1 0 112px;
              padding-left: 2rem;
              //justify-content: center;
            }

            &.number-of-nodes {
              flex: 1 0 65px;
              padding-left: 1rem;
              //justify-content: center;
            }

            &.number-of-shards {
              flex: 1 0 65px;
              padding-left: 0.5rem;
              //justify-content: center;
            }

            &.notifications-sent-clusters {
              flex: 1 0 133px;
              padding-left: 2rem;
              //justify-content: center;
            }

            &.cluster-cost {
              flex: 1 0 108px;
              justify-content: center;
            }

            &.potential-savings {
              flex: 1 0 130px;
              justify-content: center;
            }

            &.menu {
              flex: 1 0 25px;
            }

            // TOP NOTIFICATIONS TABLE
            &.event-name {
              flex: 0 0 250px;

              .name-wrapper {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            &.notifications-sent {
              flex: 1 0 116px;
              justify-content: center;
            }

            &.no-clusters {
              flex: 1 0 82px;
              justify-content: center;
            }

            &.failed-to-send {
              flex: 1 0 95px;
              justify-content: center;
            }

            // NOTIFICATIONS HISTORY TABLE
            &.notification-time {
              flex: 0 0 180px;
            }

            &.event-title {
              flex: 1 1 250px;
              overflow: hidden;
              padding-right: 0.5rem;

              .name-wrapper {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &.link {
                  color: var(--info-card-text-link);
                }
              }
            }

            &.event-open-time {
              flex: 0 0 180px;
            }

            &.cluster-title {
              flex: 1 1 200px;
            }

            &.notification-status {
              flex: 0.5 1 180px;
            }

            &.endpoint-name {
              flex: 0.5 1 150px;
              display: flex;
              gap: 5px;

              mat-icon {
                width: 1rem;
                height: 1rem;
                font-size: 1rem;
              }
            }

            &:first-child {
              padding-left: 1.25rem;
              padding-right: 0.5rem;
              border-radius: 5px 0 0 5px;
              border-width: 1px 0 1px 1px;
              width: 100%;
              overflow: hidden;
            }

            &:last-child {
              border-radius: 0 5px 5px 0;
              border-width: 1px 1px 1px 0;
            }

            &.centered-icon {
              mat-icon {
                margin: auto;
                width: 1.5rem;
                height: 1.5rem;
              }

              .show-on-hover {
                visibility: hidden;
              }
            }

            &.link {
              color: var(--info-card-text-link);
            }

            .info-icon {
              height: 14px;
              width: 14px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

.suspects-table {
  height: inherit;

  ::ng-deep .top-suspects-table {
    overflow: hidden;
    border-radius: 0;

    .p-datatable-tbody > tr {
      font-size: 14px;
      cursor: pointer;
      color: var(--panel-content-text);
      background: var(--panel-content-item-card);
      border-color: var(--panel-content-item);
    }

    .p-datatable-thead {
    }

    .p-datatable-thead > tr > th {
      font-size: 12px;
      color: var(--panel-content-text);
      background: var(--panel-content-item-card);
      border-color: var(--input-dropdown-search-bg);
      padding: 8px;
      white-space: pre-line;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-child {
        //border-radius: 10px 0 0 10px;
      }

      &:last-child {
        justify-content: center;
      }

      &.p-sortable-column {
        &.p-highlight {
          color: #4B81F5;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .p-datatable-flex-scrollable, .p-datatable-flex-scrollable .p-datatable-scrollable-view, .p-datatable-flex-scrollable .p-datatable-scrollable-wrapper {
    //background: #fff;
    border-radius: 20px;
    padding: 0 5px;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 8px;
    color: var(--panel-content-text);
    background-color: var(--panel-content-item-card);
    border-color: var(--input-dropdown-search-bg);

    .p-button.p-button-text.p-button-plain {
      color: var(--panel-content-text);
    }

    &:first-child {
      //border-radius: 10px 0 0 10px;
    }

    &:last-child {
      //border-radius: 0 10px 10px 0;
    }
  }

  .p-datatable .p-datatable-scrollable-header, .p-datatable .p-datatable-scrollable-footer {
    background: transparent;
  }

  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > .top-content:not(.p-highlight):hover {
    background: transparent;
    cursor: default;
  }

  .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
    background: transparent;
  }

  .p-column-filter-menu-button-active {

    .p-column-filter {
      background: var(--table-header-filter-active);
      border-radius: 50px;

      .p-column-filter-menu-button {
        color: #495057;
      }
    }
  }

  .p-paginator {
    font-size: 12px;
    background: var(--panel-content-sub-bg);
    border: none;
    flex-wrap: nowrap;
    padding: 8px;
    justify-content: flex-end;

    .p-paginator-current {
      color: var(--main-text);
    }

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
      &:not(.p-disabled):not(.p-highlight):hover {
        background: var(--dropdown-hover);
        border: var(--grid-line-opacity);
        border-radius: 10px;
      }

      &.p-link:focus {
        box-shadow: none;
      }

      &:hover {
        span {
          color: var(--table-button-icon-hover);
        }
      }
    }

    .p-paginator-pages {
      display: flex;
      flex-wrap: nowrap;

      .p-paginator-page {
        font-size: 12px;
        color: var(--main-text);
        border-radius: 10px;
        opacity: .6;

        &.p-highlight {
          opacity: 1;
          font-size: 0.8rem;
          background: transparent;
          font-weight: bold;
          border: none;

          &:hover {
            background: var(--dropdown-hover);
            border: var(--grid-line-opacity);
          }
        }

        &:not(.p-highlight) {
          color: var(--main-text);

          &:hover {
            opacity: 1;
            background: var(--dropdown-hover);
            border: var(--grid-line-opacity);
          }
        }
      }

      .p-link:focus {
        box-shadow: none;
      }
    }

    .p-paginator-rpp-options {

      .p-dropdown-panel {
        background: var(--input-search-bg);
        color: var(--main-text);

        .p-dropdown-items {
          .p-dropdown-item {
            color: var(--main-text);

            &.p-highlight {
              background: var(--dropdown-selected);
              color: #31394d;
            }

            &:not(.p-highlight):not(.p-disabled):hover {
              background: var(--dropdown-hover);
            }
          }
        }
      }

      &.p-dropdown {
        background: transparent;
        border: none;
        border-bottom: 1px solid #f9c400;
        border-radius: 0;

        &:not(.p-disabled) {
          &.p-focus {
            box-shadow: none;
          }
        }

        .p-dropdown-label {
          font-size: 0.8rem;
          color: var(--main-text);
        }
      }
    }


  }


  .p-datatable.top-suspects-table { // 1700px - 2200px
    .p-datatable-wrapper {
      .p-datatable-table {
        .p-datatable-thead {
          tr {
            th {
              &:first-child {
                flex: 0 0 50px;
              }

              &:nth-child(2) {
                flex: 0 0 180px;
              }

              &:nth-child(3) {
                flex: 1 0 235px;
              }

              &:nth-child(4) {
                flex: 0 0 90px;
              }

              &:nth-child(5) {
                flex: 0 0 85px;
              }

              &:nth-child(6) {
                flex: 0 0 110px;
              }

              &:nth-child(7) {
                flex: 0 0 55px;
              }
            }
          }
        }

        .p-datatable-tbody {
          tr {
            td {
              &:first-child {
                flex: 0 0 50px;
              }

              &:nth-child(2) {
                flex: 0 0 180px;
              }

              &:nth-child(3) {
                flex: 1 0 235px;
                overflow: hidden;
              }

              &:nth-child(4) {
                flex: 0 0 90px;
              }

              &:nth-child(5) {
                flex: 0 0 85px;
              }

              &:nth-child(6) {
                flex: 0 0 110px;
              }

              &:nth-child(7) {
                flex: 0 0 55px;
              }
            }
          }
        }

        @media all and (max-width: $xl) { // 1200px - 1700px
          .p-datatable-thead {
            tr {
              th {
                &:nth-child(1) {
                  flex: 0 0 50px;
                }

                &:nth-child(2) {
                  flex: 1 0 160px;
                }

                &:nth-child(3) {
                  flex: 2 0 215px;
                }

                &:nth-child(4) {
                  flex: 0 0 85px;
                }

                &:nth-child(5) {
                  flex: 0 0 55px;
                }

                &:nth-child(6) {
                  flex: 0 0 110px;
                }

                &:nth-child(7) {
                  flex: 0 0 55px;
                }
              }
            }
          }
          .p-datatable-tbody {
            tr {
              td {
                &:nth-child(1) {
                  flex: 0 0 50px;
                }

                &:nth-child(2) {
                  flex: 1 0 160px;
                }

                &:nth-child(3) {
                  flex: 2 0 215px;
                }

                &:nth-child(4) {
                  flex: 0 0 85px;
                }

                &:nth-child(5) {
                  flex: 0 0 55px;
                }

                &:nth-child(6) {
                  flex: 0 0 110px;
                }

                &:nth-child(7) {
                  flex: 0 0 55px;
                }
              }
            }
          }
        }

        @media all and (min-width: $xl-screen-width) { // above 2200px
          .p-datatable-thead {
            tr {
              th {
                &:nth-child(1) {
                  flex: 0 0 80px;
                }

                &:nth-child(2) {
                  flex: 0 0 210px;
                }

                &:nth-child(4) {
                  flex: 0 0 120px;
                }

                &:nth-child(5) {
                  flex: 0 0 120px;
                }

                &:nth-child(6) {
                  flex: 0 0 123px;
                }
              }
            }
          }
          .p-datatable-tbody {
            tr {
              td {

                &:nth-child(1) {
                  flex: 0 0 80px;
                }

                &:nth-child(2) {
                  flex: 0 0 210px;
                }

                &:nth-child(4) {
                  flex: 0 0 120px;
                }

                &:nth-child(5) {
                  flex: 0 0 120px;
                }

                &:nth-child(6) {
                  flex: 0 0 123px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: $xl-screen-width) {
    .p-datatable.top-suspects-table {
      .p-datatable-tbody > tr {
        font-size: 0.9rem;
      }

      .p-datatable-thead > tr > th {
        font-size: 0.9rem;
        //padding: 25px;
      }
    }

    .p-datatable .p-datatable-tbody > tr > td {
      padding: 12px;
    }

    .p-paginator-bottom {
      font-size: 0.9rem;

      &.p-paginator {
        padding: 12px;

        .p-paginator-pages {
          .p-paginator-page {
            font-size: 0.9rem;

            &.p-highlight {
              font-size: 1rem;
            }
          }
        }

        .p-paginator-rpp-options {
          &.p-dropdown {
            .p-dropdown-label {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: $xl) { // 1200px - 1700px
    .p-datatable.top-suspects-table {
      .p-datatable-tbody > tr {
        font-size: 13px;
      }
    }
  }
}

.sidebar-panel {
  table, th, td {
    text-align: center;
    border: 1px solid;
  }

  code, json-editor {
    table, th, td {
      border: none !important;
      text-align: left;
    }
  }
}
