.p-inputwrapper {
  .p-multiselect {
    &.opster-multiSelect {
      min-height: 41px;
      background: var(--app-bg);
      border-radius: 10px;
      border: 1px solid #ced4da;
      align-items: center;

      &.md-width {
        width: 20rem;
      }

      &.lg-width {
        width: 400px;
      }

      &.max-width {
        width: 100%;
      }

    }

    &.dashboard-multiselect {
      .p-multiselect-label-container {
        .p-multiselect-label {
          min-width: 150px;
          font-size: 0.875rem;
          font-weight: 500;
          display: flex;
          gap: 7px;

          .selected-item-wrapper {
            display: flex;
            align-items: center;
            gap: 7px;
          }
        }
      }
    }

    .p-multiselect-label-container {
      .p-multiselect-label {
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
  }
}

.p-multiselect-panel {
  &.opster-panel {
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    background: var(--app-bg);

    .p-multiselect-header {
      padding: 1rem;
      background: var(--app-bg);
      border-color: var(--border);

      .p-multiselect-filter-container {
        .p-multiselect-filter {
          background: var(--input-search-bg);
          border-color: var(--border);

          //&:enabled:focus {
          //  box-shadow: none;
          //}
        }
      }
    }

    .p-multiselect-items-wrapper {
      .p-multiselect-items {
        .p-multiselect-item {
          padding: 8px 16px;
          line-height: 24px;
          color: var(--main-text);

          &:focus {
            box-shadow: none;
          }

          &.p-highlight {
            background: var(--dropdown-selected);
            color: #313131;
          }

          &:not(.p-highlight):not(.p-disabled):hover {
            background: var(--dropdown-hover);
            color: var(--main-text);
          }

          .wrapped-text {
            max-width: 500px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
