.p-splitter .p-splitter-gutter {
  background: var(--app-bg);
  //opacity: 0.1;
}

::ng-deep .p-divider.p-divider-horizontal {
  margin: 0.5rem 0;
}

::ng-deep .p-divider .p-divider-content {
  background: var(--input-divider-text);
}

.gray {
  .p-divider .p-divider-content {
    background: var(--panel-content-tabs);
  }
}

.p-float-label {
  label {
    color: var(--input-label);

    &.login {
      color: #FEC400;
    }
  }
}

.dark {
  .p-divider .p-divider-content {
    background: #313131;
    color: #fff;
  }
}

.divider-or {
  .p-divider {
    .p-divider-content {
      b {
        opacity: 0.6;
      }
    }
  }
}

.p-inputwrapper {

  .p-multiselect {
    width: 16rem;
    background: transparent;
    border: none;
    border-bottom: 1px solid #f9c400;
    border-radius: 0;

    .ui-multiselect-panel .ui-multiselect-empty-message {
      display: none !important;
    }
  }
}

.global-toast {
  &.p-toast-top-right {
    z-index: 1000;
  }
}

.mat-divider {
  border-color: var(--divider-color);
}

// p-multiSelect
.opster-multiSelect-group-panel {
  min-width: 20rem;
  width: max-content;
  max-width: 27rem;
  border-radius: 10px;

  &.p-multiselect-panel {
    .p-multiselect-header {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      .p-multiselect-close {
        display: none;
      }
    }

    .p-multiselect-items-wrapper {
      overflow-x: hidden;

      .p-multiselect-items {
        font: 500 14px/24px Poppins, sans-serif;
        letter-spacing: normal;

        .p-multiselect-empty-message {
          height: 250px;
          color: var(--main-text);
        }

        .p-multiselect-item {
          padding-left: 26px;

          .align-items-center {
            overflow: hidden;

            &.wrap-text {
              white-space: initial;
            }

            span {
              display: inherit;
              flex-grow: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              color: var(--panel-content-text);
            }
          }

          .p-checkbox {
            .p-checkbox-box {
              width: 18px;
              height: 18px;

              .p-checkbox-icon {
                font-size: 0.8rem;
              }
            }
          }

          &.p-disabled {
            opacity: 0.3;

            & * {
              pointer-events: auto;
            }
          }

        }

        .p-multiselect-item-group {
          background: transparent;

          .align-items-center {
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--group-list-text);
            font-size: 0.8rem !important;

            .checkbox {
              //width: 100%;
            }

            .label {
              margin-left: 0.5rem;

              .mat-icon {
                height: 14px;
                width: 14px;
                margin-right: 5px;
              }
            }

            .p-checkbox {
              .p-checkbox-box {
                width: 18px;
                height: 18px;
                box-shadow: none;
                outline: none;

                &.p-disabled {
                  opacity: 0.3;
                }

                .p-checkbox-icon {
                  font-size: 0.8rem;
                }
              }
            }

            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .p-checkbox-label {
              cursor: pointer;
              line-height: 1.2;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.p-disabled {
                opacity: 0.3;
              }
            }
          }
        }
      }
    }
  }

}

// p-multiSelect-group
.p-multiSelect-group {
  &.p-multiselect {
    background: var(--input-search-bg);
    border-radius: 10px;
    height: 32px;
    line-height: 32px;
    border: none;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 500;
    width: 20rem;

    &.quick {
      width: auto;
      background: var(--app-bg);
    }

    &.small {
      width: 12rem;
    }

    &.small-md {
      width: 14rem;
    }

    &.autosize {
      width: auto;
    }

    &.light-bg {
      background: var(--app-bg);
      border: 1px var(--grid-line-opacity) solid;
    }

    &:not(.p-disabled).p-focus {
      box-shadow: none;
    }

    &.selected-icon {
      .selected-item-wrapper {
        //display: flex;
      }
    }

    &.gpt {
      width: auto;
      //background: transparent;
      //border-radius: 0;
      //border-bottom: 1px solid #f9c400;
      background: var(--app-bg);
    }

    .p-multiselect-trigger {
      .p-multiselect-trigger-icon {
        &.arrow_down {
          width: 0;
          height: 0;
          border-left: 5px solid rgba(0, 0, 0, 0);
          border-right: 5px solid rgba(0, 0, 0, 0);
          border-top: 5px solid;
          margin: 0 4px;
        }
      }
    }

    .p-multiselect-label-container {
      .p-multiselect-label {
        &.p-multiselect-label-empty {
          visibility: visible;
        }

        .item-value {
          display: flex;

          .item-name {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &.p-placeholder {
          display: flex;
          color: var(--menu-text);
        }
      }
    }


  }
}

.opster-multiSelect-panel, .opster-multiSelect-group-panel {
  &.p-multiselect-panel {
    background: var(--input-search-bg);
    border-radius: 10px;

    .p-multiselect-header {
      background: var(--input-search-bg);

      .p-checkbox {
        .p-checkbox-box {
          width: 18px;
          height: 18px;

          .p-checkbox-icon {
            font-size: 0.8rem;
          }
        }
      }

      .p-multiselect-filter-container {
        .p-multiselect-filter-icon {
          color: var(--input-search-icon);
        }

        .p-inputtext {
          background: var(--input-dropdown-search-bg);
          box-shadow: none;
          border: none;
          padding: 0.2rem 0.2rem 0.2rem 0.5rem;
        }
      }
    }

    .p-multiselect-items {

      .p-multiselect-empty-message {
        height: 100px;
        color: var(--main-text);
      }

      .p-multiselect-item {
        color: var(--main-text);

        &:focus {
          box-shadow: none;
        }

        &:not(.p-highlight):not(.p-disabled):hover {
          background: var(--dropdown-hover);
          color: var(--main-text);
          box-shadow: none;
        }

        &.p-highlight {
          box-shadow: none;
          background: transparent;
          color: var(--main-text);
        }

        .list-item-wrapper {
          display: flex;
          align-items: center;
          gap: 7px;
        }
      }
    }
  }
}

// p-dropdown
.opster-dropdown-top {
  &.p-dropdown {
    background: var(--input-search-bg);
    border-radius: 10px;
    height: 32px;
    line-height: 32px;
    border: none;
    align-items: center;

    &.lg-width {
      width: 20rem;

      .p-dropdown-panel {
        min-width: 20rem;
        width: max-content;
        max-width: 27rem;
      }
    }

    &.md-width {
      width: 20rem;

      .p-dropdown-panel {
        width: 20rem;
      }
    }

    &.sm-width {
      border-radius: 0 10px 10px 0;

      &.rounded-border-radius {
        border-radius: 10px;
      }

      .p-dropdown-panel {
        width: unset;
      }
    }

    &.sm-10-width {
      border-radius: 0 10px 10px 0;
      min-width: 10rem;

      &.rounded-border-radius {
        border-radius: 10px;
      }

      .p-dropdown-panel {
        width: unset;
      }
    }

    .p-dropdown-trigger {
      .p-dropdown-trigger-icon {
        &.arrow_down {
          width: 0;
          height: 0;
          border-left: 5px solid rgba(0, 0, 0, 0);
          border-right: 5px solid rgba(0, 0, 0, 0);
          border-top: 5px solid;
          margin: 0 4px;
        }
      }
    }

    &:not(.p-disabled).p-focus {
      border: none;
      box-shadow: none;
    }

    .p-dropdown-panel {
      font-size: 0.8rem;
      font-weight: 500;
      border-radius: 10px;
      background: var(--input-search-bg);
      width: 18rem;

      .p-dropdown-header {
        background: transparent;

        .p-dropdown-filter-container {
          .p-dropdown-filter-icon {
            color: var(--input-search-icon);
          }
        }

        .p-inputtext {
          background: var(--input-dropdown-search-bg);
          box-shadow: none;
          border: none;
          padding: 0.2rem 0.2rem 0.2rem 0.5rem;
        }
      }

      .p-dropdown-items {

        .p-dropdown-empty-message {
          height: 250px;
          color: var(--main-text);
        }

        .p-dropdown-item {
          padding: 8px 16px;
          line-height: 24px;
          color: var(--main-text);

          &:not(.p-highlight):not(.p-disabled):hover {
            background: var(--dropdown-hover);
            color: var(--main-text);
          }

          .item-list {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.wrap-text {
              white-space: initial;
            }
          }
        }

        .p-dropdown-item-group {
          background: transparent;
          color: var(--main-text);
          opacity: 0.6;
        }

        .p-dropdown-item.p-highlight {
          background-color: var(--dropdown-selected);
          color: #313131;
        }
      }
    }

    .p-dropdown-label {
      font-family: 'Poppins', sans-serif;
      font-size: 0.8rem;
      font-weight: 500;
      color: var(--main-text);

      .item-value {
        display: flex;

        .selected-item {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

// p-overlaypanel
.opster-overlaypanel.p-overlaypanel {
  display: block;
  top: 2.5rem !important;
  //right: 9rem !important;
  left: auto !important;
  padding: 0;
  background: transparent;
  border-radius: 10px;
  border: none;
  box-shadow: none;

  &:before {
    content: none;
  }

  &:after {
    content: none;
  }

  .p-overlaypanel-content {
    padding: 0;
    display: flex;

    .ranges {
      display: flex;
      gap: 5px;

      .absolute-time-range {
        position: absolute;
        left: -333px;
        background: var(--input-search-bg);
        display: grid;
        grid-gap: 10px;
        padding: 10px 20px;
        height: fit-content;
        box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
        border-radius: 10px;

        .title-time-range {
          color: var(--main-text);
          opacity: 0.6;
          margin: 0.3rem 0;
        }

        .calendar {
          display: grid;
          grid-gap: 5px;

          .calendar-name {
            font-size: 14px;
            opacity: 0.7;
            color: var(--main-text)
          }
        }

        .time-range-action {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
        }
      }
    }

  }
}

.p-column-filter-overlay {
  background-color: var(--panel-content-item);
  color: var(--panel-content-text);

  .p-column-filter-constraints {
    background-color: var(--panel-content-item);
    color: var(--panel-content-text);

    .p-multiselect-panel {
      background-color: var(--panel-content-item);
      color: var(--panel-content-text);

      .p-multiselect-header {
        background-color: var(--panel-content-item);
        color: var(--panel-content-text);
      }
    }

    .p-multiselect-items-wrapper {
      background-color: var(--panel-content-item);
      color: var(--panel-content-text);

      .p-multiselect-items {

        .p-multiselect-empty-message {
          background-color: var(--panel-content-item);
          color: var(--panel-content-text);
        }

        .p-multiselect-item {
          background-color: var(--panel-content-item);
          color: var(--panel-content-text);
        }
      }
    }
  }

  .p-column-filter-buttonbar {
    display: none;
  }
}

.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  background: var(--dropdown-hover);
  color: var(--main-text);
}

.p-overlaypanel {
  background: var(--input-search-bg);
  color: var(--main-text);

  &:after {
    border-bottom-color: var(--input-search-bg);
  }

  &:before {
    border-bottom-color: var(--input-search-bg);
  }

  .slider-times-body {
    display: grid;
    grid-gap: 10px;

    .slider-times {
      display: grid;
      grid-gap: 10px;
      margin-bottom: 20px;

      span {
        font-weight: 500;
      }

      &.refresh {
        display: flex;
        align-items: center;
      }
    }
  }

}

