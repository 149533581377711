.upload-file-action {
  font-size: 0.9rem;
  text-align: center;
  min-height: 300px;

  .upload-file-card {
    border: dashed 3px #979797;
    background: var(--panel-content-item-card-benefits);
    border-radius: 20px;
    height: 100%;
    display: grid;
    align-items: center;
    padding: 2rem;

    &.color {
      background: var(--input-refresh-bg);
      min-height: 300px;
    }

    .drop-text {
      font-size: 18px;
      font-weight: 400;
      display: grid;
      height: 100%;
      grid-gap: 0.5rem;

      .upload-slow-log-icon {
        width: 36px;
        height: 45px;
        margin: auto;
      }

      .first-row {
        line-height: 1.5;
        font-weight: 600;
      }

      .explanations {
        color: var(--main-text);
        opacity: 0.7;
        font-size: 12px;
        line-height: 1;
      }

      .browse {
        border-radius: 6px;
        font-size: 0.9rem;
        background: #dee2e6;
        font-weight: 600;
        width: 128px;
        margin: auto;
        color: #495057 !important;
      }
    }

    .file-text {
      .name {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        .file-del {
          height: 30px;
          width: 30px;
          cursor: pointer;

          .mat-icon {
            color: #ee6540;
          }
        }

        .file-name {
          font-size: 0.9rem;
          margin-left: 10px;
        }
      }
    }
  }
}
