::ng-deep .mat-mdc-dialog-container {
  padding: 0 !important;
  border-radius: 20px !important;

  .mdc-dialog__container {
    .mdc-dialog__surface {
      background: var(--dialog-bg);
      border-radius: 20px;
    }

    .mat-mdc-dialog-content {
      margin: 0 !important;
      padding: 0 2rem !important;

      .mat-mdc-form-field {
        //width: 26em;
      }
    }


    .mdc-dialog__title {
      font-size: 1.2rem;
      font-weight: 600;
      color: var(--dialog-title-color);
    }

    .mdc-dialog__content {
      color: var(--main-text);
    }

    .mat-mdc-dialog-title {
      display: grid;
      grid-template-columns: 1fr 24px;
      justify-content: space-between;
      padding: 2rem 2rem 1rem 2rem;
      //border-bottom: solid 2px var(--dialog-title-color);
      align-items: baseline;
      margin: 0;

      &:before {
        content: none;
      }

      mat-icon {
        cursor: pointer;
      }
    }

    .mat-mdc-button {
      background: var(--button-bg);
      color: var(--button-cancel-text);
      width: 90px;
    }


    .mat-mdc-dialog-actions {
      justify-content: flex-end;
      margin-bottom: 0 !important;
      background-color: transparent;
      padding: 1rem 2rem 2rem 2rem;
      align-items: flex-end;

      &.space-between {
        justify-content: space-between;
      }

      .mat-mdc-button {
        background: var(--button-save-bg);
        color: var(--button-text);
        width: 90px;

        //&.mat-button-base + .mat-button-base {
        //  background: var(--button-bg);
        //  color: var(--button-cancel-text);
        //
        //}
      }

    }
  }
}

.supplementary {

  p {
    font-size: 15px !important;
    font-weight: 500;
  }

  h2 {
    font-size: 18px !important;
    font-weight: 800;
  }

  h3 {
    font-size: 0.9rem !important;
    font-weight: 600;
  }

  .EnlighterJSRAW, .schema-how-to-step-text {
    font-size: 0.8rem !important;
    font-weight: 400;
    max-width: 600px;
    background: #272822;
    color: #fff;
    overflow: auto;
    border-radius: 10px;
    padding: 10px;
  }

  .EnlighterJSRAW {
    padding: 15px;
  }
}

::ng-deep .cdk-overlay-container {
  z-index: 2147483647;
}

.add-end-point-dialog {
  /*TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container .mat-mdc-dialog-content {
    max-height: 80vh;
  }
}

.p-dialog {
  border: 1px solid rgba(49, 49, 49, 0.1490196078);
  border-radius: 20px;
  background: var(--panel-content-bg);

  &.video-dialog {
    width: 67vw;
    height: 80vh;

    .video-dialog-wrapper {
      height: 100%;
      width: 100%;
      overflow: hidden;
      border-radius: 0 0 20px 20px;
    }
  }

  &.contact-us {
    .p-dialog-header {
      background: #FFFFFF;
    }

    .p-dialog-content {
      background: #FFFFFF;
    }
  }

  .p-dialog-header {
    padding: 1rem;
    background: #F4F4F4;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .p-dialog-title {
      font-size: 1.5rem;
    }
  }

  .p-dialog-content {
    background: #F4F4F4;

    &:last-of-type {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  &.no-padding {
    .p-dialog-content {
      padding: 0;
    }
  }
}

.p-dynamic-dialog, .p-dialog {
  &.tour-dialog {
    width: auto;
    height: 90vh;
    aspect-ratio: 1.7;

    .p-dialog-header {
      background: var(--dialog-bg);
    }

    .p-dialog-content {
      background: var(--dialog-bg);

      .tour-dialog-wrapper {
        height: 100%;
        width: 100%;
        overflow: hidden;
        border-radius: 0 0 20px 20px;
      }
    }

  }
}
