::ng-deep .mat-mdc-menu-panel {
  background: var(--input-search-bg);
  border-radius: 10px;

  .mat-mdc-menu-content {
    min-width: 150px;

    .mat-mdc-menu-item {
      span {
        color: var(--panel-content-text);
      }
    }
  }


  .mat-mdc-menu-item:hover:not([disabled]), .mat-menu-item.cdk-program-focused:not([disabled]), .mat-menu-item.cdk-keyboard-focused:not([disabled]), .mat-menu-item-highlighted:not([disabled]) {
    background: var(--dropdown-hover);
    cursor: pointer;

  }
}

::ng-deep div.mat-mdc-select-panel {
  background: var(--input-search-bg) !important;
  opacity: 0.99 !important;

  .mat-mdc-option {
    color: var(--panel-content-text) !important;
    /*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
    &.mat-option-disabled {
      opacity: 0.3;
    }

    .mat-icon {
      margin-right: 5px;
    }
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) {
    .mdc-list-item__primary-text {
      color: #31394d !important;
    }

    &:not(.mat-mdc-option-multiple) {
      background: var(--dropdown-selected);
    }
  }

  .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) {
    background: var(--dropdown-selected);
    color: #31394d !important;
  }

  .mat-mdc-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
    background: var(--dropdown-hover);
  }

}

::ng-deep .activity-select {
  &.mat-mdc-select-panel {
    margin-top: 1.9em;
    margin-left: 0.25em;
    min-width: 100%;
    border: solid 1px var(--input-search-border);
    border-radius: 10px;
  }
}

::ng-deep .chart-select {
  &.mat-mdc-select-panel {
    margin-top: 23px;
    margin-left: 0.3em;
    min-width: 100%;
    border: solid 1px var(--input-search-border);
    border-radius: 10px;
  }
}

::ng-deep .opster-menu-select {
  &.nodes {
    max-width: 240px;

    &.mat-mdc-select-panel {
      margin-left: 20px;
    }
  }

  &.cluster {
    max-width: 250px;
  }

  &.mat-mdc-select-panel {
    margin-top: 32px;
    //margin-left: 1em;
    border: solid 1px var(--input-search-border);
    border-radius: 10px;
    min-width: 100% !important
  }
}

::ng-deep .dialog-select {
  &.mat-mdc-select-panel {
    margin-top: 32px;
    margin-left: 1.8em;
  }
}

::ng-deep .mat-mdc-optgroup-label {
  color: var(--panel-content-text);
  font-size: 0.8rem !important;
  opacity: 0.7;
}

.opster-field {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  ::ng-deep .mat-form-field-wrapper {
    font-size: 0.8rem;
    padding: 8px 0 20px !important;
    line-height: 1.5;
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
      width: 240px;
    }

    /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-trigger {
      padding: 0px 3px 0px 10px;
      line-height: 32px;
      background: var(--input-search-bg);
      height: 32px;
      border-radius: 0 10px 10px 0;

    }
  }
}


