.p-overlay-badge .p-badge {
  height: 15px;
  font-size: 10px;
  padding: 0 5px;
  line-height: 15px;
  font-weight: 600;

  &.p-badge-info {
    background-color: #313131 !important;
    right: 8px;
    top: 3px;
  }
}
