/* width */
::-webkit-scrollbar {
  //width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
}


:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .4) !important;
  border-radius: 4px !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, .4) !important;
  border-radius: 4px !important;
}
