::ng-deep .wizard {
  ::ng-deep .mat-step-header {
    padding: 7px 14px 7px 0;
    color: white;

    .mat-step-label.mat-step-label-active {
      color: white;
    }

    &[aria-selected='true'] {
      background: var(--app-bg);
      border-radius: 20px;
      color: #313131;

      .mat-step-label.mat-step-label-active {
        color: var(--main-text);
        font-size: 14px;
        font-weight: 500;
      }

    }

  }


  ::ng-deep .mat-horizontal-stepper-header-container {
    display: none;
  }

  ::ng-deep .mat-horizontal-content-container {
    background: #F8F7F7;
    padding: 0;
  }


  ///* Border */
  //::ng-deep .mat-form-field-underline .mat-form-field-ripple {
  //  background-color: rgba(0, 0, 0, 0.87) !important;
  //}

  //::ng-deep .mat-select-arrow {
  //  color: rgba(0, 0, 0, 0.54) !important;
  //}


  ::ng-deep .mat-mdc-select-panel .mat-mdc-option.mat-selected:not(.mat-mdc-option-multiple) {
    background: #fecb03;
    color: black;
  }


  ::ng-deep .mat-mdc-outlined-button[disabled][disabled] {
    opacity: 0.3;
    color: #292e33;
  }

  ::ng-deep .mat-step {
    pointer-events: none;
  }

  ::ng-deep .bg-primary {
    background-color: white !important;
  }

  ::ng-deep .mat-step-icon-content {
    font-size: 18px;
    font-weight: 600;

    .mat-icon {
      width: 25px;
      height: 25px;
      font-size: 25px;
      color: #292e33;
    }
  }

  .wizard-steps {
    // matirial wizard
    ::ng-deep .mat-step-icon {
      color: white;
      border: solid 2px white;
      width: 38px;
      height: 38px;
      background-color: transparent;


      &.mat-step-icon-state-done {
        background-color: #fff;
        color: #313131;
        border: solid 2px #fff;
      }

      &.mat-step-icon-selected {
        background-color: #fec400;
        color: #313131;
        border: none;
      }
    }

    ::ng-deep .mat-vertical-stepper-header {
      margin: 50px 0;
    }

    ::ng-deep .mat-vertical-content-container {
      margin-left: 43px;
      height: 1px;
    }

    ::ng-deep .mat-vertical-content {
      padding: 0;
    }

    ::ng-deep .mat-stepper-vertical-line::before {
      top: -50px;
      bottom: -50px;
      left: -26px;
      border-left: 3px solid #FFFFFF;
    }

    ::ng-deep .mat-stepper-horizontal, .mat-stepper-vertical {
      background-color: transparent !important;
    }
  }

}
