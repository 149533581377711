::ng-deep .p-skeleton {
  border-radius: 20px;
}

.box-spinner-flex {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-skeleton {
  &.skeleton-card {
    border-radius: 20px;
  }
}
