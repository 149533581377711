.p-overlaypanel {
  &.tooltip-styled {
    background: var(--card-bg);
    color: var(--main-text);
    border-radius: 10px;
    padding: 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);

    .p-overlaypanel-content {
      padding: 0;

      .content-wrapper {
        padding: 0 2rem;

        .item {
          color: var(--info-card-text-link);
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
