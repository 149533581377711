.field-names {

  .p-orderlist {

    .p-orderlist-controls {
      display: none;
    }

    .p-orderlist-filter-container {
      border-radius: 10px 10px 0 0;
      background: var(--panel-content-sidebar);
      border: none;

      .p-orderlist-filter-input {
        border-radius: 10px;
      }
    }

    .p-orderlist-list {
      border-radius: 0 0 10px 10px;
      background: var(--panel-content-sidebar);
      border: none;

      .p-orderlist-item {
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);
        border-radius: 0;

        &:hover {
          background: var(--panel-content-hover) !important;
        }

        &:focus {
          box-shadow: none;
        }

        &.p-highlight {
          background: transparent linear-gradient(270deg, #FFFFFF00 0%, #80808089 100%) 0% 0% no-repeat padding-box;

          .field-name-item {
            font-weight: 600;
          }
        }


        .field-name-item {
          color: var(--main-text);
          font-weight: 400;
          padding: 10px 5px;
          display: flex;

          .field {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            direction: rtl;
            text-align: left;
            max-width: 25vw;
            margin: 0;
          }
        }


      }


    }
  }
}
