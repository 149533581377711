.customize-listbox.p-listbox {
  border-radius: 10px;
  background: var(--input-bg2);
  border-color: var(--border-input);

  .p-listbox-list-wrapper {
    max-height: 14.5rem;
    overflow: auto;
  }

  .p-listbox-list .p-listbox-item {
    color: var(--main-text) !important;
    pointer-events: none;

    &:focus {
      box-shadow: none;
    }
  }
}

.event-list.p-listbox {
  border-radius: 0 0 5px 5px;
  background: var(--app-bg);
  border-color: var(--border);

  &:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    background: var(--dropdown-hover);
    color: var(--main-text);
  }

  .p-listbox-header {
    background: var(--app-bg);
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid var(--border);
    padding: 1rem;

    .p-listbox-filter-container {
      background: var(--app-bg);
      border: var(--border);

      .p-listbox-filter {
        background: var(--input-search-bg);

        &.p-inputtext {
          box-shadow: none;
          border-color: var(--border);
        }
      }
    }
  }

  .p-listbox-list-wrapper {
    max-height: 14.5rem;
    overflow: auto;
  }

  .p-listbox-list .p-listbox-item {
    color: var(--main-text);

    &.p-highlight {
      background: var(--dropdown-selected);
      color: #313131;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.ranges.p-listbox {
  border: none;
  font-size: 0.8rem;
  background: var(--input-search-bg);

  .p-listbox-list-wrapper {
    height: auto;
    overflow: auto;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
    border-radius: 10px;

    .p-listbox-list {
      padding: 0;
    }

    .p-listbox-item {
      color: var(--main-text);
      height: 37px;

      &.p-highlight {
        background: var(--dropdown-selected);
        box-shadow: none;
        color: #313131;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        background: var(--dropdown-hover);
        color: var(--main-text);
      }
    }
  }
}

.gifs-section {


  .names {
    .p-listbox {
      background: transparent;

      .p-listbox-list-wrapper {
        min-width: 240px;
      }

      .list-gif-names {
        .p-listbox-list {
          .p-listbox-item {
            font-size: 18px;
            border-radius: 10px;
            padding: 20px;
            margin: 10px 0;
            display: flex;
            gap: 5px;
            justify-content: space-between;

            &.p-highlight {
              background-color: var(--panel-content-bg);
              border-left: 3px solid var(--side-menu-selected-border);
              min-width: 240px;
              border-top-right-radius: 20px;
              border-bottom-right-radius: 20px;
              border-left-width: 6px;
              justify-content: space-between;
              padding-right: 10px;
              opacity: 1;
              display: flex;

              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}
