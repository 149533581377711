@import "../../../assets/common-sizes";

.page-sub-menu {
  //display: grid;
  //grid-template-columns: 240px 1fr;
  height: 100%;
  margin: 0;
  color: var(--panel-content-text);

  .installation-wizard-and-error {
    position: fixed;
    top: 0;
    left: 341px;
    height: 109px;
    width: calc(100% - 341px);
    z-index: 12;
    transition: left 0.2s;

    &.open {
      left: 501px;
      width: calc(100% - 501px);
    }

    .opster-token {
      color: #313131;
      position: absolute;
      top: 114px;
      z-index: 90;
      width: 100%;
      transition: top 0.5s;
      display: flex;
      flex-wrap: wrap;
    }

    .logs-error {
      color: #313131;
      position: absolute;
      top: 61px;
      z-index: 90;
      width: 100%;
      transition: top 0.5s;
      display: flex;
      flex-wrap: wrap;

      &.showing {
        top: 114px
      }

      .logs-error-wrapper {
        font-size: 13px;
        width: 100%;
        background: #EECACB;
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 0.4rem 0.8rem;

        ::ng-deep mat-icon.logs-error-icon {
          min-width: 24px;
          min-height: 24px;

          svg path {
            fill: #A41318;
          }
        }

        .logs-error-text {
          min-width: 200px;
          max-width: 900px;
        }

        .logs-error-date {
          white-space: nowrap;
        }

        .logs-error-close-icon-wrapper {
          min-width: 2rem;
          min-height: 2rem;
          border-radius: 50%;
          background: transparent;
          transition: background-color .2s;
          cursor: pointer;
          margin-left: auto;
          display: flex;

          ::ng-deep mat-icon.logs-error-close-icon {
            width: 1rem;
            height: 1rem;
            margin: auto;

            svg path {
              fill: #313131;
            }
          }

          &:hover {
            background: rgba(255, 255, 255, .3);
          }
        }
      }

      .expand-tab {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        gap: 5px;
        height: 45px;
        padding: 0.5rem 0.7rem;
        margin-left: 33px;
        border-radius: 0 0 12px 12px;
        background: #EECACB;

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }

        ::ng-deep mat-icon.logs-error-icon {
          min-width: 24px;
          min-height: 24px;

          svg path {
            fill: #A41318;
          }
        }

        ::ng-deep mat-icon.chevron_down {
          max-width: 18px;
          max-height: 18px;

          svg path {
            fill: #313131;
          }
        }
      }
    }
  }

  .page-wrapper {
    width: calc(100% - 280px);
    min-width: 40vw;
    position: absolute;
    overflow: auto;
    margin-left: 240px;
    //margin-top: 75px;
    padding: 50px 0 0 50px;

    &.open {
      width: calc(100% - 310px);
    }

    .sub-menu-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .sub-menu-title-header {
        display: flex;
        gap: 12px;
        align-items: center;
        margin-bottom: 14px;

        .sub-menu-title-header-icon {
          width: 48px;
          height: 48px;
        }

        .sub-menu-title-header-label {
          font-size: 36px;
          font-weight: 600;
          display: flex;
          align-items: center;
          gap: 1rem;

          .tour-wrapper {
            align-items: center;

            .play-icon {
              width: 1rem;
              height: 1rem;
              font-size: 1rem;
            }
          }
        }
      }

      .sub-menu-title-subheader {
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      .sub-menu-title-paragraph {
        font-size: 0.9rem;
        margin-bottom: 20px;
        margin-top: 40px;

        .step {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    .sub-menu-body {
      .sub-menu-body-shipping {
        max-width: 100%;

        &.max-width {
          max-width: 80em;
        }
      }

      &.show-videos {
        display: flex;
        gap: 60px;
        overflow: auto;

        .sub-menu-body-videos {
          display: flex;
          gap: 20px;
          align-items: center;

          .sub-menu-body-videos-line {
            width: 25%;
            height: 5px;
            background: #F5C543;
            margin-bottom: 15px;
            margin-top: 48px;
          }

          .sub-menu-body-videos-text {
            margin-bottom: 18px;
            font-size: 0.9rem;
            font-weight: 500;
          }

          .sub-menu-body-videos-cta {
            font-size: 26px;
            font-weight: 400;
            line-height: 28px;
            cursor: pointer;
            height: 150px;
            border: 6px solid #C9C9C9;
            border-radius: 0 10px 10px 10px;
            background: var(--button-video-bg);
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            &:hover {
              border: 6px solid #F5C543;

              .sub-menu-body-videos-cta-play {
                border: 1px solid #F5C543;

                .sub-menu-body-videos-cta-play-inner:before,
                .sub-menu-body-videos-cta-play-inner:after {
                  background: #F5C543;
                }
              }
            }

            .sub-menu-body-videos-cta-text {
              width: 152px;
            }

            .sub-menu-body-videos-cta-play {
              width: 41px;
              height: 41px;
              background: var(--button-video-play-bg);
              border: 1px solid var(--button-video-play-line);
              border-radius: 50%;

              //&:hover {
              //  border: 1px solid #F5C543;
              //}

              .sub-menu-body-videos-cta-play-inner,
              .sub-menu-body-videos-cta-play-inner:before,
              .sub-menu-body-videos-cta-play-inner:after {
                width: 23px;
                height: 23px;
              }

              .sub-menu-body-videos-cta-play-inner {
                overflow: hidden;
                position: relative;
                margin: 2em auto;
                border-radius: 20%;
                transform: translateX(-28%) translateY(-190%) rotate(120deg) skewY(30deg) scaleX(0.866);
              }

              .sub-menu-body-videos-cta-play-inner:before,
              .sub-menu-body-videos-cta-play-inner:after {
                position: absolute;
                background: var(--button-video-play-line);
                pointer-events: auto;
                content: '';
              }

              .sub-menu-body-videos-cta-play-inner:before {
                border-radius: 20% 20% 20% 53%;
                transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(.866) translateX(-24%);
              }

              .sub-menu-body-videos-cta-play-inner:after {
                border-radius: 20% 20% 53% 20%;
                transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(.866) translateX(24%);
              }
            }
          }
        }
      }
    }

    .page-footer {
      width: 50vw;
      margin-top: 20px;

      .page-footer-header {
        font-size: 18px;
        font-weight: 500;
      }

      .page-footer-subheader {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 25px;
        gap: 4vw;

        .text {
          font-size: 0.9rem;
        }
      }

      .page-footer-bottom {
        //width: 70%;
        margin-bottom: 100px;
        display: flex;
        justify-content: space-between;

        .text {
          font-size: 0.8rem;

          a {
            cursor: pointer;
          }
        }

        .loader-wrapper {
          display: flex;
          align-items: center;
          gap: 12px;

          .mat-mdc-progress-spinner svg circle {
            stroke: var(--spinner-color-progress);
          }
        }
      }
    }
  }


  .menu-setting {
    position: fixed;
    left: 90px;
    min-width: 240px;
    height: 100%;
    z-index: 5;
    background: var(--app-bg);
    transition: left 0.2s;

    &.open {
      left: 250px;
    }

    .menu-wrapper {
      display: block;
      height: 100%;
      position: relative;
      border-right: 1px solid var(--border);
      width: 252px;

      .menu-sticky {
        position: sticky;
        height: calc(100% - 50px);
        overflow-y: auto;
        width: 265px;
        overflow-x: hidden;

        ::ng-deep .p-panelmenu {

          // submenu
          .p-panelmenu-content {
            padding: 0;
            border: none;
            color: var(--menu-text);
            background: transparent;

            .p-menuitem {

              .p-menuitem-link {
                font-size: 0.8rem;
                font-weight: 600;
                height: 50px;
                opacity: 0.5;
                padding-left: 2.5rem;

                &:focus {
                  box-shadow: none;
                }

                &:not(.p-disabled):hover {
                  background: transparent;
                  opacity: 1;
                }

                &.p-menuitem-link-active {
                  background-color: var(--panel-content-bg);
                  border-left: 3px solid var(--side-menu-selected-border);
                  min-width: 240px;
                  border-top-right-radius: 20px;
                  border-bottom-right-radius: 20px;
                  border-left-width: 6px;
                  justify-content: space-between;
                  padding-right: 10px;
                  opacity: 1;

                  &:not(.p-disabled):hover {
                    background-color: var(--panel-content-bg);
                    opacity: 1;
                  }

                  .p-menuitem-icon {
                    display: block;
                    height: 14px;
                    order: 1;
                  }
                }

                .p-menuitem-text {
                  color: var(--menu-text);
                  flex: 1;
                }

                .p-menuitem-icon {
                  display: none;
                }
              }
            }
          }

          .p-panelmenu-header > a {

            &:focus {
              box-shadow: none;
            }

          }

          // root menu
          .p-panelmenu-panel {

            .p-panelmenu-header.disabled-tab {
              cursor: default;
              display: none;

              .p-panelmenu-header-link {
                cursor: default;

                &:hover {
                  opacity: 0.6;
                }
              }
            }

            .p-panelmenu-header {
              .p-panelmenu-header-content {
                border: none;
                background: transparent;
                box-shadow: none;

                .p-panelmenu-header-action {
                  color: var(--menu-text);
                  font-size: 0.8rem;
                  font-weight: 600;
                  background: transparent;
                  border: none;
                  cursor: pointer;
                  text-decoration: none;
                  height: 50px;
                  display: flex;
                  flex: 1;
                  align-items: center;
                  min-width: 240px;
                  opacity: 0.5;
                  padding: 10px;

                  .p-menuitem-text {
                    flex-grow: 1;
                  }

                  .p-menuitem-icon {
                    display: none;
                  }

                  &:hover {
                    background: transparent;
                    opacity: 1;
                  }

                  &.p-menuitem-link-active {
                    background-color: var(--panel-content-bg);
                    border-left: 3px solid var(--side-menu-selected-border);
                    min-width: 240px;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                    border-left-width: 6px;
                    justify-content: space-between;
                    padding-right: 10px;
                    opacity: 1;

                    .p-menuitem-icon {
                      display: block;
                      height: 14px;
                      order: 1;
                    }
                  }

                  &.not-active {
                    pointer-events: none;
                    cursor: default;
                  }
                }

              }
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: $md) {
  .page-footer-subheader {
    flex-direction: column;
  }
}
