.mat-mdc-outlined-button:not(:disabled) {
  color: inherit !important;
}

.mat-mdc-button:not(:disabled) {
  color: inherit !important;
}

.button-opster {
  font-family: 'Poppins', sans-serif;
  min-width: 158px;
  width: auto;
  height: 36px !important;
  border-radius: 10px !important;
  border: none !important;
  background: var(--panel-content-bg);
  color: var(--main-text);
  //margin-top: 20px;
  cursor: pointer;

  &.no-color {
    background: transparent;
    border: transparent;
  }

  &.border-line {
    background: transparent;
    border: 1px solid #D9D9D9 !important;
    height: 35px !important;
    border-radius: 10px;
    font-size: 0.9rem;
    width: 112px
  }

  &.small-btn {
    min-width: auto;
    height: auto !important;
    border-radius: 10px !important;
    min-height: 32px;
    white-space: nowrap;
  }

  &.nowrap {
    white-space: nowrap;
  }

  &.xsmall {
    font-size: 15px;
    min-width: 142px;
    height: 32px !important;
    margin: 0;

  }

  &.metricbeat {
    height: 64px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    width: fit-content;
    min-width: 255px;
    white-space: nowrap;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &.big-btn {
    min-width: 200px !important;
  }

  &.large-btn {
    min-width: 258px;
  }

  &.medium-btn {
    width: auto !important;
  }

  &:hover:not([disabled]) {
    opacity: 0.8;
  }

  &.yellow {
    background: var(--button-yellow-bg) !important;
    color: var(--button-yellow-text) !important;
    padding: 0 30px;
    line-height: 32px !important;
  }

  &.marketing {
    font-size: 14px;
    padding: 14px;
    height: 48px !important;
    width: 171px;
    background: transparent;
    border: 1px solid #d9d9d9 !important;
    color: #313131;
    transition: all linear .3s;
    z-index: 10;
    white-space: nowrap;

    &:hover:not([disabled]) {
      opacity: 1;
      border: 1px solid #f1a94b !important;
    }

    .chevron {
      background: url("https://mlbtihvv1ztx.i.optimole.com/mlbtihvv1ztx-opster-oz07e9d/w:auto/h:auto/q:auto/https://opster.com/wp-content/themes/opster/images/icon_right_arrow_yellow.svg") center center no-repeat;
      width: 15px;
      height: 15px !important;
      margin-left: 10px;
      padding-right: 3px;
    }
  }

  &.dialog-action {
    height: 48px !important;
    font-size: 0.9rem;
    font-weight: 600;

    &.secondary {
      font-weight: 400;
    }
  }

  &.no-space {
    margin: 0;
  }

  &.social {
    background: transparent;
    border: 1px solid #d9d9d9 !important;
    color: #fff;
    margin: 0;
    width: 290px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.action {
    background: var(--panel-content-bg) !important;
    margin-top: 0;
    font-weight: 600;
    color: var(--main-text) !important;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  }

  &.content-bg {
    background: var(--app-bg) !important;
    margin-top: 0;
  }

  &.gray {
    background: var(--button-gray-bg);
    color: var(--button-gray-text) !important;
    margin-top: 0;

    &.border {
      border: 1px solid var(--button-gray-border) !important;
    }

    &.border-gray {
      border: 1px solid var(--main-text) !important;
      height: 32px !important;
      line-height: 29px;
    }

  }


  .mat-icon {
    width: 1rem;
    height: 1rem;
    line-height: 1;
    margin-right: 5px;

    &.btn-new-icon {
      ::ng-deep svg path:nth-child(2) {
        fill: var(--button-text);
      }
    }
  }
}

.top-chat-gpt {
  cursor: pointer;
  background: #FBC02D;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #313131;
  gap: 10px;
  height: 34px !important;
  padding: 10px 20px 10px 10px;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 101;
  //border: 1px solid var(--input-refresh-border);

  &.settings {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  &.small {
    padding: 10px;
  }

  .ops-gpt-text {
    font-size: 0.8rem;
    display: block;
  }

  &:hover {
    opacity: 0.6;

    .ops-gpt-text {
      display: block;
    }
  }

  .chat-icon {
    width: 24px;
    height: 24px !important;
    font-size: 24px;
  }
}

button:disabled {
  opacity: 0.5;
  cursor: auto;
}


.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 10px;
  display: inline-block;

  &.live {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    border: 2px solid var(--panel-content-sub-bg);
  }


  &.not_active {
    background: var(--tag-bg);
  }

  &.GREEN, &.active {
    background: #29CB97;
  }
}


.ops-icon {
  height: 28px;
  width: 28px;
  border-radius: 3px;
  display: grid;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.more {
    background: var(--button-bg);
    color: var(--button-cancel-text);
  }

  &.copy {
    background: var(--button-save-bg);
    color: var(--button-text);
    border: 1px solid #D4D4D7;
    border-radius: 3px;
    padding: 2px;

    ::ng-deep .mat-icon {
      height: 21px;
      width: 21px;

      ::ng-deep svg {
        path {
          fill: var(--main-text) !important;
        }
      }
    }
  }
}


/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
::ng-deep .mat-button-focus-overlay {
  background-color: transparent !important;
}


