// primeng
::ng-deep input.p-inputtext, .p-inputtext {
  width: 100%;
  border-radius: 10px;
  background: var(--app-bg);
  border-color: var(--border);
  position: relative;
  color: var(--main-text);

  &.login {
    background: #141414;
    color: white;

    &:enabled:focus {
      -webkit-box-shadow: 0 0 0 0.2rem #8ad0ff9c;
      box-shadow: 0 0 0 0.2rem #8ad0ff9c;
    }
  }

  &:enabled:focus {
    box-shadow: none;
    //border-color: transparent;
  }

}

.opster-input {
  width: 100%;
}

::ng-deep .p-inputtext {
  &.p-inputtextarea {
    width: 100%;
    height: 100% !important;
    //max-height: 10rem;
    min-height: 2rem;
    overflow: scroll !important;
    padding-right: 40px;
    box-shadow: 0px 1px 7px 2px var(--panel-shadow);
    border: none;
    font-size: 0.8rem;
    background: var(--app-bg);
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
::ng-deep .mat-headline .mat-form-field-flex > .mat-form-field-infix {
  padding: 0px 0px 0.4em 0px !important;
  border-top: 0.6em solid transparent;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
::ng-deep .input-border .mat-form-field-flex > .mat-form-field-infix {
  padding: 14px;
  border: 1px solid #D8D8D8;
  border-radius: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
::ng-deep .input-outline .mat-form-field-flex > .mat-form-field-infix {
  padding: 0.4em 0px !important;
  width: 240px;
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-label-wrapper {
    top: -1.5em;

  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
::ng-deep .mat-form-field-label {
  padding: 0.2em 0.75em 0 0.75em;
  line-height: 2 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 10px !important;
  padding: 0 1em 0 1em !important;
  align-items: center;
  height: 32px;
  width: 300px;
  background-color: var(--input-search-bg) !important;
  //border: solid 1px var(--input-search-border);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  //-webkit-box-shadow: 0 0 0 30px var(--app-bg) inset;
  -webkit-text-fill-color: var(--main-text);

  &#sign-in-email,
  &#sign-in-password,
  &#sign-up-email,
  &#sign-up-full-name,
  &#sign-up-account-name,
  &#sign-up-password,
  &#sign-up-confirm-password {
    //-webkit-box-shadow: 0 0 0 30px var(--app-bg) inset;
    -webkit-text-fill-color: white;
    caret-color: white;
  }
}

::ng-deep .no-underline {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    display: none !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, var(--main-text) 0%, var(--main-text) 33%, transparent 0%);

}

::ng-deep .mat-form-field-appearance-legacy:not(.mat-form-field-disabled) {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    background-color: #FEC400 !important;

  }

  .mat-mdc-form-field-hint {
    color: var(--main-text);
    font-size: 12px;
    opacity: 0.8;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
::ng-deep .mat-form-field-label {
  //color: var(--input-label) !important;
  opacity: 0.5;
  padding: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
::ng-deep .mat-select-value {
  font-weight: 400;
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value-text {
    color: var(--main-text);
  }
}

::ng-deep .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  color: var(--main-text);

}

::ng-deep .mat-mdc-select {
  font-weight: 600;
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-arrow {
    color: var(--main-text) !important;
  }
}

.mat-mdc-paginator .mat-mdc-select-value {
  color: var(--main-text);
}

.mat-mdc-select-arrow {
  color: var(--main-text) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #FBC02B !important;
}

::ng-deep .p-field-radiobutton {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
}

.select-search-top {
  position: sticky;
  top: 0;
  background: var(--input-search-bg);
  z-index: 1;
  padding: 16px;
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-search {
    padding: 0 16px;
    height: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    background: var(--input-dropdown-search-bg);


    ::placeholder {
      color: #aeaeae;
    }

    mat-icon {
      color: #aeaeae;
      cursor: pointer;
    }
  }
}

// Radio Button
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
::ng-deep .mat-mdc-radio-button.mat-accent.mat-radio-checked {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-inner-circle {
    background-color: #527ff0;

  }

  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-outer-circle {
    border-color: #527ff0;

  }
}

.mat-mdc-radio-group {
  .mat-mdc-radio-button {
    .mdc-radio__background {
      .mdc-radio__outer-circle {
        border-color: var(--radio-outer-color-unchecked) !important;
      }
    }
  }

  .mat-mdc-radio-button.mat-mdc-radio-checked {
    .mdc-radio__background {
      .mdc-radio__outer-circle {
        border-color: var(--radio-outer-color) !important;
      }
    }
  }

  .mat-mdc-radio-button,
  .mat-mdc-radio-button.mat-mdc-radio-checked {
    .mdc-radio__background {
      &::before {
        background-color: var(--radio-inner-color);
      }

      //.mdc-radio__outer-circle {
      //  border-color: var(--radio-outer-color) !important;
      //}

      .mdc-radio__inner-circle {
        border-color: var(--radio-inner-color) !important;
      }
    }

    .mat-ripple-element {
      background-color: var(--radio-inner-color);
    }
  }
}

// selectbutton
::ng-deep .p-selectbutton {
  display: flex;

  .p-button {
    font-size: 0.8rem;
    padding: 5px 10px;
    height: 30px;
    background: var(--panel-content-sub-bg);
    color: var(--main-text);
    border-color: var(--main-text);

    &:not(.p-disabled):not(.p-highlight):hover {
      background: var(--dropdown-hover);
      border: var(--grid-line-opacity);
      color: var(--main-text);
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:first-child {
      border-radius: 8px 0 0 8px !important
    }

    &:last-child {
      border-radius: 0 8px 8px 0 !important
    }

    &.p-highlight {
      background: var(--button-time-bg);
      color: var(--button-time-text);
      font-weight: 600;
      border: none;

      &:hover {
        background: var(--button-time-bg);
        color: var(--button-time-text);
        opacity: 0.7;
      }
    }

  }

  &.select-button-gap {
    gap: 5px;

    .p-button {
      width: 42px;
      padding: 0;
      border-radius: 5px !important;
      border: 1px solid var(--border) !important;
    }
  }
}

::ng-deep .p-button {

  &.no-bg {
    background: transparent;
    border: none;
    padding: 5px;

    &:hover {
      background: transparent;
      border: none;
      opacity: 0.6;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.input-title-link {
  color: #4072ee;
  margin-left: 5px;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}

.field {
  margin-bottom: 1rem;

  .block {
    display: block !important;
  }

  & > label {
    margin-bottom: 0.5rem;
  }

  & > small {
    margin-top: 0.25rem;
    font-size: 11px;
  }
}

.float-label-small {
  margin-left: 0.25rem;
  font-size: 11px;
}
