::ng-deep .p-picklist {

  .p-picklist-list {
    background: var(--app-bg);
    border-color: var(--border);

  }

  .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
    background: var(--dropdown-hover);
    border-radius: 10px;
  }

  .p-picklist-item {
    color: var(--main-text) !important;

    &:focus {
      box-shadow: none !important;
    }

    &.p-highlight {
      background: var(--dropdown-selected) !important;
      border-radius: 10px;
      color: #31394d !important;
    }
  }

  .p-picklist-header {
    border-radius: 20px 20px 0 0;
    background: var(--app-bg);
    border-color: var(--border);
    color: var(--main-text);
  }

  .p-picklist-filter-container {
    background: var(--app-bg);
    border-color: var(--border);

    .p-picklist-filter-input {
      border-radius: 10px;
      background: var(--input-search-bg);
      border-color: var(--border);
    }

  }

  .p-picklist-buttons .p-button {
    border-radius: 10px;
  }
}


::ng-deep .p-picklist-list-wrapper {
  width: 15vw;
}


.product-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;

  .mat-icon {
    width: 35px;
    height: 35px;
    //box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 1rem;
  }


  .product-list-detail {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  .product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
  }

  .product-category {
    vertical-align: middle;
    line-height: 1;
  }
}

@media screen and (max-width: 576px) {
  .product-item {
    flex-wrap: wrap;

    .image-container {
      width: 100%;
      text-align: center;
    }

    img {
      margin: 0 0 1rem 0;
      width: 100px;
    }
  }
}
