// primeng
.shards-view-dropdown {
  &.p-dropdown {
    width: auto;
    background: transparent;
    border: none;
    border-bottom: 1px solid #f9c400;
    border-radius: 0;

    //.p-dropdown-trigger {
    //  display: none;
    //}

    .p-dropdown-label {
      color: var(--main-text);
    }

    .p-dropdown-panel {
      font-size: 0.8rem;
      color: #4a4a4a;
      border-radius: 10px;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.3);
      background: var(--input-search-bg);

      .p-dropdown-items {
        .p-dropdown-item {
          padding: 0.5rem;
          color: var(--main-text);

          &:nth-child(2n):not(:nth-child(6)) {
            border-bottom: 1px solid rgba(49, 49, 49, 0.3);
          }

          &:not(.p-highlight):not(.p-disabled):hover {
            background: var(--dropdown-hover);
            color: var(--main-text);

          }

          &.p-highlight {
            background: var(--dropdown-selected);
            color: #31394d !important;
          }
        }
      }
    }

    .sort-by-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.border {
        border-bottom: 1px solid rgba(49, 49, 49, 0.3);
      }

      .sort-by-icon {
        font-size: 18px;
        opacity: 0.4;
      }
    }


    &:not(.p-disabled).p-focus {
      box-shadow: none;
    }
  }
}

.shards-view-multiSelect {
  .p-multiselect-panel {
    background: var(--input-search-bg);
    border-radius: 10px;

    .p-multiselect-header {
      background: var(--input-search-bg);

      .p-multiselect-filter-container .p-inputtext {
        background: var(--input-dropdown-search-bg);
        box-shadow: none;
        border: none;
      }
    }

    .p-multiselect-items .p-multiselect-item {
      color: var(--main-text);

      &:focus {
        box-shadow: none;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        background: var(--dropdown-hover);
        color: var(--main-text);
        box-shadow: none;
      }

      &.p-highlight {
        box-shadow: none;
        background: transparent;
        color: var(--main-text);
      }
    }
  }
}

// p-dropdown
.opster-dropdown {
  &.p-dropdown {
    background: var(--app-bg);
    border-radius: 10px;
    border: 1px solid #ced4da;
    align-items: center;

    &.max-width {
      width: 100%;
    }

    &.lg-width {
      width: 20rem;

      .p-dropdown-panel {
        min-width: 20rem;
        width: max-content;
        max-width: 27rem;
      }
    }

    &.md-lg-width {
      width: 400px;

      .p-dropdown-panel {
        width: 400px;
      }
    }

    &.md-width {
      width: 20rem;

      .p-dropdown-panel {
        width: 20rem;
      }
    }

    &.sm-width {
      border-radius: 0 10px 10px 0;

      .p-dropdown-panel {
        width: unset;
      }
    }

    &.xsm-width {
      border-radius: 10px;
      width: 14rem;

      .p-dropdown-panel {
        width: unset;
      }
    }

    .p-dropdown-trigger {
      .p-dropdown-trigger-icon {
        &.arrow_down {
          width: 0;
          height: 0;
          border-left: 5px solid rgba(0, 0, 0, 0);
          border-right: 5px solid rgba(0, 0, 0, 0);
          border-top: 5px solid;
          margin: 0 4px;
        }
      }
    }

    &:not(.p-disabled).p-focus {
      box-shadow: none;
    }

    .p-dropdown-panel {
      font-size: 0.8rem;
      font-weight: 500;
      border-radius: 10px;
      background: var(--app-bg);

      .p-dropdown-header {
        background: transparent;

        .p-inputtext {
          background: var(--input-dropdown-search-bg);
          box-shadow: none;
          border: none;
          padding: 0.2rem 0.2rem 0.2rem 0.5rem;
        }
      }

      .p-dropdown-items {

        .p-dropdown-item {
          padding: 8px 16px;
          line-height: 24px;
          color: var(--main-text);

          &:not(.p-highlight):not(.p-disabled):hover {
            background: var(--dropdown-hover);
            color: var(--main-text);
          }

          .item-list {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.wrap-text {
              white-space: initial;
            }
          }
        }

        .p-dropdown-item-group {
          background: transparent;
          color: var(--main-text);
          opacity: 0.6;
        }

        .p-dropdown-item.p-highlight {
          background-color: var(--dropdown-selected);
          color: #313131;
        }
      }
    }

    .p-dropdown-label {
      font-family: 'Poppins', sans-serif;
      font-size: 0.8rem;
      font-weight: 500;
      color: var(--main-text);

      .item-value {
        display: flex;

        .selected-item {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

// dropdown-panel
.opster-panel {
  &.p-dropdown-panel {
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 10px;
    background: var(--app-bg);

    .p-dropdown-header {
      background: transparent;

      .p-inputtext {
        background: var(--input-dropdown-search-bg);
        box-shadow: none;
        border: none;
        padding: 0.2rem 0.2rem 0.2rem 0.5rem;
      }
    }

    .p-dropdown-items {

      .p-dropdown-item {
        padding: 8px 16px;
        line-height: 24px;
        color: var(--main-text);

        &:not(.p-highlight):not(.p-disabled):hover {
          background: var(--dropdown-hover);
          color: var(--main-text);
        }

        .item-list {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.wrap-text {
            white-space: initial;
          }
        }
      }

      .p-dropdown-item-group {
        background: transparent;
        color: var(--main-text);
        opacity: 0.6;
      }

      .p-dropdown-item.p-highlight {
        background-color: var(--dropdown-selected);
        color: #313131;
      }
    }
  }
}

// p-treeSelect
.opster-tree-select {

  .p-inputwrapper {
    background: var(--input-search-bg);
    border-radius: 10px;
    height: 32px;
    line-height: 32px;
    border: none;
    align-items: center;

    .p-treeselect-items-wrapper {
      border-radius: 10px;
      overflow-x: auto;
      width: max-content;
      max-width: 15vw;

      .p-element {

        .p-tree .p-tree-container .p-treenode .p-treenode-content {

          &:focus {
            border: none !important;
            box-shadow: none !important;
          }

          .p-tree-toggler:focus {
            border: none !important;
            box-shadow: none !important;
          }

          .p-treenode-label {
            white-space: nowrap;
            color:  var(--main-text);
          }
        }

        .p-tree {
          background: var(--input-search-bg);
          padding: 4px;
          max-width: 15vw;
          width: max-content;

          .p-tree-wrapper {
            .p-tree-container {
              font-size: 0.8rem;

              .p-element {
                .p-treenode {
                  .p-treenode-children {
                    .p-element {
                      .p-treenode {
                        .p-treenode-content {

                          color: var(--main-text);

                          &:not(.p-highlight):hover {
                            background: var(--dropdown-hover);
                          }

                          &.p-highlight {
                            background-color: var(--dropdown-selected);

                            .p-treenode-label {
                              color: #313131;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .p-treeselect-panel {
      border-radius: 10px;
      right: 30px;
      background: var(--input-search-bg);
      max-width: 15vw;
      width: max-content;

      .p-treeselect-header {
        background: var(--input-search-bg);

        .p-treeselect-filter-container {
          margin: 0;

          .p-treeselect-filter {
            background: var(--input-dropdown-search-bg);
            border: none;
            box-shadow: none;
            padding: 0.2rem 0.2rem 0.2rem 0.5rem;
          }
        }

        .p-treeselect-close {
          display: none;
        }

      }
    }


    &:not(.p-disabled).p-focus {
      box-shadow: none;
      border: none;
    }
  }

  .p-treeselect-label-container {
    font-size: 0.8rem;
    font-weight: 500;

    .p-treeselect-label {

      &.p-treeselect-label-empty {
        visibility: initial;
      }

      .item-value {
        display: flex;
      }
    }
  }
}

.small-spacer-border {
  margin-left: 0.7em;
  height: 32px;
  border: solid 0.5px var(--space-border);
  margin-right: 0.7em;

  &.opacity {
    opacity: 0.3;
    height: 24px;
  }
}

.p-dropdown-opster {
  &.p-dropdown {
    width: 220px;
    background: var(--input-search-bg);
    border-radius: 10px;
    border: 1px solid #ced4da;
  }
}
